/* src/App.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');




/* Accessibility */
body {
  font-family: 'Poppins', sans-serif;
  background-color: black;
}
/* Snowfall enhancements */
.app::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  pointer-events: none;
  z-index: 1001;
}

/* Add a subtle animation to the background for a dynamic effect */
@keyframes subtleShift {
  0% { background-position: 0% 0%; }
  100% { background-position: 100% 100%; }
}

body {
  animation: subtleShift 60s ease infinite alternate;
  
}

/* This targets the canvas created by react-snowfall */
canvas {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  pointer-events: none;
  z-index: -1000;
}