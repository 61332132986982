/* Add these at the top of the file */











.company-oa-wrapper {
  max-width: 1200px;
  margin: 40px auto; /* Reduced from 80px to 40px */
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
}

.company-oa-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.company-oa-title {
  font-size: 2.2rem;
  font-weight: 700;
  color: #ffffff;
  margin: 0;
  line-height: 1.2;
  display: flex;
  align-items: center;
  gap: 10px;
}

.title-text-full {
  display: inline;
  position: relative;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
}

.title-text-short {
  display: none; /* Hide on desktop */
}

/* Mobile styles */
@media (max-width: 768px) {
  .title-text-full {
    display: none; /* Hide on mobile */
  }

  .title-text-short {
    display: block; /* Show on mobile */
  }

  .company-oa-title {
    font-size: 1.8rem;
  }

  .highlight-letter {
    font-size: 1.15em;
  }

  .batman-logo {
    font-size: 1.6rem;
  }

  .cphelper-text {
    font-size: 0.8rem;
  }

  .by-text {
    font-size: 0.6rem;
  }
}

@media (max-width: 480px) {
  .company-oa-title {
    font-size: 1.2rem;
  }

  .highlight-letter {
    font-size: 1em;
  }

  .batman-logo {
    font-size: 1.1rem;
  }

  .cphelper-text {
    font-size: 0.75rem;
  }

  .by-text {
    font-size: 0.6rem;
  }
}

.company-table {
  background: rgba(18, 18, 18, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin: 20px 0;
}

/* Table Header Styles */
.company-table-header {
  display: grid;
  grid-template-columns: 2fr 180px 80px 120px 50px;
  gap: 20px;
  align-items: center;
  padding: 15px 20px;
  background: rgba(0, 0, 0, 0.6);
  border: none; /* Removed border */
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-size: 0.9rem;
}

/* Table Body Styles */
.company-table-body {
  padding: 5px; /* Reduced padding */
  display: flex;
  flex-direction: column;
  gap: 10px; /* Reduced gap between rows */
}

/* Company Row Styles */
.company-row {
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 12px;
  margin: 8px 0;
  transition: all 0.3s ease;
  border: none; /* Removed border */
}

.company-row:hover {
  background: rgba(255, 255, 255, 0.07);
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

/* Company Data Styles */
.company-data {
  display: grid;
  grid-template-columns: 2fr 180px 80px 120px 50px;
  gap: 20px;
  align-items: center;
  padding: 15px 20px;
  border: none; /* Removed border */
}

/* Company Name Section */
.company-name {
  display: flex;
  align-items: center;
  gap: 15px;
}

.company-icon-wrapper {
  background: rgba(255, 255, 255, 0.05);
  padding: 8px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  transition: all 0.3s ease;
}

/* Date Container Styles */
.date-container {
  background: rgba(255, 255, 255, 0.05);
  padding: 6px 12px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
}

/* Questions Count Styles */
.questions-count {
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
}

/* Solution Badge Styles */
.solution-badge {
  padding: 5px 12px;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.solution-badge.available {
  background: rgba(80, 250, 123, 0.1);
  border: 1px solid rgba(80, 250, 123, 0.2);
  color: #50fa7b;
}

.solution-badge.unavailable {
  background: rgba(255, 85, 85, 0.1);
  border: 1px solid rgba(255, 85, 85, 0.2);
  color: #ff5555;
}

/* Expanded Content Styles */
.company-questions {
  background: rgba(0, 0, 0, 0.3);
  margin: 0 10px 10px;
  padding: 15px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .company-table-header,
  .company-data {
    grid-template-columns: 2fr 160px 70px 110px 50px;
    gap: 15px;
    padding: 12px 15px;
  }
}

@media (max-width: 768px) {
  .company-table-header,
  .company-data {
    grid-template-columns: 1fr 140px auto 50px;
    gap: 10px;
    padding: 10px;
  }
  
  .company-table {
    border-radius: 15px;
    margin: 10px 0;
  }
}

/* Hover Effects */
.company-row:hover .company-icon-wrapper {
  background: rgba(255, 255, 255, 0.1);
  transform: scale(1.05);
}

.company-row:hover .date-container {
  background: rgba(255, 255, 255, 0.08);
}

/* Loading State */
.company-row.loading {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.7; }
  100% { opacity: 1; }
}

.company-data {
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  position: relative;
}

/* Glassmorphism effect */
.company-data::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.02)
  );
  border-radius: 24px;
  z-index: -1;
}

.company-info-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1;
}

.company-icon-wrapper {
  width: 52px;
  height: 52px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.company-icon {
  font-size: 24px;
  color: rgba(255, 255, 255, 0.9);
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
}

.company-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.company-name-text {
  font-size: 1.4rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.95);
  letter-spacing: 0.5px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.company-meta {
  display: flex;
  align-items: center;
  gap: 15px;
}

.date-container {
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  padding: 6px 15px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  gap: 8px;
}

.questions-badge {
  background: rgba(99, 102, 241, 0.15);
  color: #6366f1;
  padding: 6px 15px;
  border-radius: 20px;
  font-size: 0.9rem;
  border: 1px solid rgba(99, 102, 241, 0.3);
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Hover effects */
.company-row:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.3);
  border-color: rgba(255, 255, 255, 0.15);
  background: rgba(20, 20, 24, 0.8);
}

.company-row:hover .company-icon-wrapper {
  transform: scale(1.05);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  border-color: rgba(255, 255, 255, 0.2);
}

.company-name {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-right: 20px; /* Add right padding */
}

.company-name:hover {
  background: rgba(0, 0, 0, 0.75);
  border-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.company-icon-wrapper {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  width: 42px; /* Slightly larger */
  height: 42px; /* Slightly larger */
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(108, 92, 231, 0.2);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  margin-right: 5px; /* Add some space after icon */
}

.company-icon-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, 
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.2)
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.company-data:hover .company-icon-wrapper::before {
  opacity: 1;
}

.company-icon {
  color: white;
  font-size: 1.4rem;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
}

.company-name-text {
  font-size: 1.2rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 0.5px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.company-name-text::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(90deg, var(--primary-color), var(--secondary-color));
  transition: width 0.3s ease;
}

.company-data:hover .company-name-text::after {
  width: 100%;
}

.company-data:hover .company-icon-wrapper {
  transform: translateY(-2px) rotate(8deg);
  box-shadow: 0 6px 15px rgba(108, 92, 231, 0.3);
}

.expand-button {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

.company-questions {
  padding: 15px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
}

.question-item {
  background: rgba(108, 92, 231, 0.05);
  border: 1px solid rgba(168, 164, 255, 0.1);
  border-radius: 12px;
  padding: 16px 20px;
  margin-bottom: 12px;
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.question-item:hover {
  background: rgba(108, 92, 231, 0.1);
  border-color: rgba(168, 164, 255, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(108, 92, 231, 0.2);
}

.question-preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.question-info {
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 15px;
  flex-wrap: nowrap;
}

.question-number {
  font-size: 1.1rem;
  color: #bd93f9;
  font-weight: bold;
  min-width: 28px;
  height: 28px;
  background: rgba(189, 147, 249, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
}

.question-title {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 12px;
  padding: 15px 20px;
  margin: 10px 0;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.question-title:hover {
  background: rgba(0, 0, 0, 0.65);
  border-color: rgba(255, 255, 255, 0.15);
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.25);
}

.question-title:hover {
  color: var(--secondary-color);
}

.question-meta {
  display: flex;
  align-items: center;
  gap: 12px;
}

.difficulty-badge {
  padding: 5px 12px;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.difficulty-badge.easy {
  background-color: rgba(80, 250, 123, 0.15);
  color: #50fa7b;
  border: 1px solid rgba(80, 250, 123, 0.3);
}

.difficulty-badge.medium {
  background-color: rgba(255, 184, 108, 0.15);
  color: #ffb86c;
  border: 1px solid rgba(255, 184, 108, 0.3);
}

.difficulty-badge.hard {
  background-color: rgba(255, 85, 85, 0.15);
  color: #ff5555;
  border: 1px solid rgba(255, 85, 85, 0.3);
}

.solved-badge {
  background-color: rgba(139, 233, 253, 0.15);
  color: #8be9fd;
  border: 1px solid rgba(139, 233, 253, 0.3);
  padding: 5px 12px;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.company-table-body {
  min-width: 100%;
  overflow-x: visible;
}

.company-row:not(:last-child) {
  border-bottom: none; /* Removed border */
}

.company-name span {
  font-weight: bold;
}

.company-date {
  font-style: italic;
  color: rgba(255, 255, 255, 0.7);
}

.company-questions-count {
  font-weight: bold;
  color: #4CAF50;
}

.solution-badge {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
  font-weight: bold;
}

.solution-badge.available {
  background-color: rgba(76, 175, 80, 0.2);
  color: #4CAF50;
}

.solution-badge.unavailable {
  background-color: rgba(244, 67, 54, 0.2);
  color: #F44336;
}

.company-data:hover .expand-button {
  transform: translateY(2px);
}

/* Add responsive adjustments */
@media (max-width: 1240px) {
  .company-oa-wrapper {
    max-width: 90%;
  }
}

@media (max-width: 768px) {
  .company-oa-wrapper {
    padding: 20px 10px;
    margin-top: 20px;
  }

  .company-table-header,
  .company-data {
    grid-template-columns: 1fr 140px auto 50px;
    gap: 10px;
  }

  .date-container {
    min-width: 100px;
    padding: 6px 8px;
    font-size: 0.85rem;
  }

  .date-ago {
    font-size: 0.75rem;
  }
}

@media (max-width: 480px) {
  .company-table-header,
  .company-data {
    grid-template-columns: 2fr 110px 1fr;
  }

  .date-container {
    min-width: 90px;
    padding: 4px 6px;
  }
}

.clickable-title {
  cursor: pointer;
  color: #4CAF50;
  transition: color 0.3s ease;
}

.clickable-title:hover {
  color: #45a049;
}

.problem-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.problem-modal-content.vscode-style {
  background: #1e1e1e;
  color: #d4d4d4;
  padding: 40px;
  border-radius: 15px;
  width: 92%;
  max-width: 1100px;
  height: 85vh;
  overflow-y: auto;
  font-family: 'Fira Code', 'Roboto Mono', 'Consolas', monospace;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  line-height: 1.8;
}

.problem-title {
  color: #61dafb;
  font-size: 32px;
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 2px solid #3c3c3c;
  text-shadow: 0 0 10px rgba(97, 218, 251, 0.5);
}

.problem-content {
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 25px;
  font-size: 18px;
}

.problem-content h4 {
  color: #ff79c6;
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.problem-description p {
  line-height: 1.8;
  margin-bottom: 20px;
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.1);
}

.problem-example pre {
  background-color: #282a36;
  padding: 20px;
  border-radius: 10px;
  overflow-x: auto;
  border-left: 5px solid #50fa7b;
  font-size: 16px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
}

.problem-constraints ul {
  list-style-type: none;
  padding-left: 0;
}

.problem-constraints li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 25px;
}

.problem-constraints li::before {
  content: '•';
  color: #50fa7b;
  position: absolute;
  left: 0;
  font-size: 24px;
  line-height: 1;
}

.problem-follow-up {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 2px solid #3c3c3c;
}

.show-solution-btn {
  margin-top: 30px;
  background-color: #bd93f9;
  color: #282a36;
  border: none;
  padding: 12px 25px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  box-shadow: 0 4px 6px rgba(189, 147, 249, 0.2);
}

.show-solution-btn:hover {
  background-color: #ff79c6;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(255, 121, 198, 0.3);
}

.lock-icon {
  margin-right: 12px;
  font-size: 20px;
}

.close-modal {
  position: absolute;
  top: 25px;
  right: 25px;
  background: none;
  border: none;
  color: #ff5555;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.close-modal:hover {
  color: #ff79c6;
  transform: rotate(90deg);
}

/* Custom scrollbar for webkit browsers */
.problem-content::-webkit-scrollbar {
  width: 12px;
}

.problem-content::-webkit-scrollbar-track {
  background: #282a36;
  border-radius: 6px;
}

.problem-content::-webkit-scrollbar-thumb {
  background-color: #6272a4;
  border-radius: 6px;
  border: 3px solid #282a36;
}

.problem-content::-webkit-scrollbar-thumb:hover {
  background-color: #8be9fd;
}

.code-editor {
  width: 100%;
  height: 300px;
  background: #2d2d2d;
  color: #d4d4d4;
  border: 1px solid #3c3c3c;
  border-radius: 3px;
  padding: 10px;
  font-family: 'Consolas', 'Courier New', monospace;
  font-size: 14px;
  resize: vertical;
}

/* Add these new styles */
.solution-button-container {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.get-solution-btn {
  background: var(--primary-light);
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.get-solution-btn:hover {
  background: var(--primary-color);
  color: white;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(108, 92, 231, 0.2);
}

.get-solution-btn svg {
  font-size: 0.8rem;
}

.solution-email-form {
  min-width: 320px;
}

.solution-form-content {
  display: flex;
  align-items: center;
  background: var(--primary-light);
  padding: 4px;
  border-radius: 20px;
  border: 1px solid var(--secondary-color);
  gap: 8px;
  animation: slideIn 0.3s ease-out;
}

.solution-email-form input {
  flex: 1;
  padding: 8px 16px;
  border-radius: 20px;
  border: none;
  background: transparent;
  color: var(--text-color);
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.solution-email-form input::placeholder {
  color: var(--secondary-color);
  opacity: 0.7;
}

.solution-email-form input:focus {
  outline: none;
}

.solution-email-form button {
  background: var(--secondary-color);
  color: var(--primary-color);
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 6px;
}

.solution-email-form button:hover {
  background: white;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(168, 164, 255, 0.2);
}

.solution-email-form button svg {
  font-size: 0.9rem;
}

/* Update these color variables at the top of your CSS file */
:root {
  --primary-color: #6c5ce7;
  --primary-light: rgba(108, 92, 231, 0.1);
  --secondary-color: #a8a4ff;
  --text-color: #f8f9fa;
  --border-color: rgba(255, 255, 255, 0.1);
  --hover-color: #8c7ae6;
  --gradient-start: #6c5ce7;
  --gradient-end: #a8a4ff;
  --amazon-color: #ff9900;
  --google-color: #4285f4;
  --microsoft-color: #00a4ef;
  --apple-color: #a6b1b7;
  --meta-color: #0668e1;
  --linkedin-color: #0077b5;
  --uber-color: #000000;
  --netflix-color: #e50914;
  --tesla-color: #cc0000;
  --oracle-color: #f80000;
  --adobe-color: #ff0000;
  --stripe-color: #635bff;
  --snapchat-color: #fffc00;
  --button-shadow: rgba(108, 92, 231, 0.3);
}

/* Update the animation */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}


/* Add these new styles */
.question-actions {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: 15px;
}

.view-problem-btn {
  background: rgba(80, 250, 123, 0.1);
  color: #50fa7b;
  border: 1px solid rgba(80, 250, 123, 0.3);
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  transition: all 0.3s ease;
  white-space: nowrap;
  min-width: fit-content;
}

.view-problem-btn:hover {
  background: rgba(80, 250, 123, 0.2);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(80, 250, 123, 0.2);
}

.view-problem-btn svg {
  font-size: 0.8rem;
}

/* Add company-specific gradient styles */
[data-company="amazon"] .company-icon-wrapper {
  background: linear-gradient(135deg, var(--amazon-color), #232f3e);
}

[data-company="google"] .company-icon-wrapper {
  background: linear-gradient(135deg, #4285f4, #34a853, #fbbc05, #ea4335);
}

[data-company="microsoft"] .company-icon-wrapper {
  background: linear-gradient(135deg, #00a4ef, #7fba00, #f25022, #ffb900);
}

[data-company="meta"] .company-icon-wrapper,
[data-company="facebook"] .company-icon-wrapper {
  background: linear-gradient(135deg, #0668e1, #0091ff);
}

/* Add more company-specific styles as needed */

/* Add these new styles */
.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0; /* Removed padding */
  background: none; /* Removed background */
  backdrop-filter: none; /* Removed blur */
  border: none; /* Removed border */
  margin-bottom: 20px;
  box-shadow: none; /* Removed shadow */
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
}

.get-help-button {
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 28px;
  background: linear-gradient(
    45deg,
    #0088cc,  /* Telegram's brand color */
    #00a0e3
  );
  border: none;
  border-radius: 50px;
  color: white;
  font-weight: 600;
  font-size: 1.1rem;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 136, 204, 0.3);
}

.get-help-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 136, 204, 0.4);
  background: linear-gradient(
    45deg,
    #0088cc,
    #0099dd
  );
}

.get-help-button:active {
  transform: translateY(0);
}

.telegram-icon {
  font-size: 1.4rem;
  animation: float 2s ease-in-out infinite;
}

/* Add floating animation for the Telegram icon */
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
}

/* Update shine effect for Telegram colors */
.button-shine {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transform: rotate(45deg);
  animation: shine 3s infinite;
}

/* Add hover effect for the text */
.button-text {
  position: relative;
  z-index: 1;
  transition: all 0.3s ease;
}

.get-help-button:hover .button-text {
  transform: scale(1.05);
}

/* Update mobile styles */
@media (max-width: 768px) {
  .get-help-button {
    padding: 10px 24px;
    font-size: 1rem;
  }
  
  .telegram-icon {
    font-size: 1.2rem;
  }
}

/* Add a subtle pulse effect when hovering */
.get-help-button:hover .telegram-icon {
  animation: float 2s ease-in-out infinite, pulse 1s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

/* Update date styles for horizontal layout */
.date-container {
  margin-left: -30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px; /* Reduced from 10px */
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  padding: 4px 10px; /* Reduced from 6px 14px */
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  min-width: 180px; /* Reduced from 200px */
  text-align: left;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  font-size: 0.85rem;
  letter-spacing: 0.3px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.company-data:hover .date-container {
  background: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.date-container {
  font-family: 'Inter', sans-serif;
  color: #bd93f9;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.3px;
  position: relative;
}

.date-container::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(189, 147, 249, 0.5);
}

.date-ago {
  font-size: 0.8rem; /* Reduced from 0.9rem */
  color: rgba(189, 147, 249, 0.7);
  font-weight: 400;
  font-style: italic;
}

/* Update grid template for the new date width */
.company-table-header,
.company-data {
  grid-template-columns: 2fr 180px 80px 120px 50px; /* Adjusted column widths */
  gap: 20px; /* Reduced gap */
  align-items: center;
  padding: 15px 20px;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .date-container {
    margin-left: -25px;
    min-width: 170px;
    padding: 3px 8px; /* Further reduced padding */
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {
  .date-container {
    margin-left: -20px;
    min-width: 150px;
    padding: 2px 6px; /* Minimal padding for mobile */
    font-size: 0.75rem;
    gap: 4px;
  }
}

/* Update company name text size */
.company-name-text {
  font-size: 1.3rem; /* Increased from 1.1rem */
  font-weight: 600;
  color: #f8f8f2;
  letter-spacing: 0.5px;
  position: relative;
  padding-bottom: 2px;
  background: linear-gradient(120deg, var(--primary-color), var(--secondary-color));
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

/* Update date container styles */
.date-container {
  margin-left: -30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px; /* Reduced from 10px */
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  padding: 4px 10px; /* Reduced from 6px 14px */
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  min-width: 180px; /* Reduced from 200px */
  text-align: left;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  font-size: 0.85rem;
  letter-spacing: 0.3px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.date-ago {
  font-size: 0.8rem; /* Reduced from 0.9rem */
  color: rgba(189, 147, 249, 0.7);
  font-weight: 400;
  font-style: italic;
}

/* Update responsive styles */
@media (max-width: 1024px) {
  .company-name-text {
    font-size: 1.2rem;
  }
  
  .date-container {
    margin-left: -25px;
    min-width: 170px;
    padding: 3px 8px; /* Further reduced padding */
    font-size: 0.8rem;
  }
  
  .date-ago {
    font-size: 0.75rem;
  }
}

@media (max-width: 768px) {
  .company-name-text {
    font-size: 1.1rem;
  }
  
  .date-container {
    margin-left: -20px;
    min-width: 150px;
    padding: 2px 6px; /* Minimal padding for mobile */
    font-size: 0.75rem;
    gap: 4px;
  }
  
  .date-ago {
    font-size: 0.7rem;
  }
}

/* Update date container styles with glass effect */
.date-container {
  margin-left: -30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background: rgba(255, 255, 255, 0.08); /* Lighter glass effect */
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  padding: 4px 10px;
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  min-width: 180px;
  text-align: left;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.9); /* Brighter text */
  font-weight: 500;
  font-size: 0.85rem;
  letter-spacing: 0.3px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.company-data:hover .date-container {
  background: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.date-ago {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.6); /* Subtle gray for time ago */
  font-weight: 400;
  font-style: italic;
}

/* Add separator dot */
.date-container span:first-child::after {
  content: '•';
  margin: 0 3px; /* Reduced from 5px */
  color: rgba(255, 255, 255, 0.4);
}

/* Responsive styles */
@media (max-width: 1024px) {
  .date-container {
    margin-left: -25px;
    min-width: 170px;
    padding: 3px 8px; /* Further reduced padding */
    font-size: 0.8rem;
    background: rgba(255, 255, 255, 0.06);
  }
}

@media (max-width: 768px) {
  .date-container {
    margin-left: -20px;
    min-width: 150px;
    padding: 2px 6px; /* Minimal padding for mobile */
    font-size: 0.75rem;
    gap: 4px;
  }
}

/* Add these variables to :root if not already present */
:root {
  --glass-bg: rgba(255, 255, 255, 0.08);
  --glass-border: rgba(255, 255, 255, 0.1);
  --glass-shadow: rgba(0, 0, 0, 0.1);
  --text-primary: rgba(255, 255, 255, 0.9);
  --text-secondary: rgba(255, 255, 255, 0.6);
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .company-name {
    padding-left: 20px;
  }
  
  .company-table-header,
  .company-data {
    grid-template-columns: 1fr 220px 1fr 50px;
  }
}

@media (max-width: 768px) {
  .company-name {
    padding-left: 15px;
  }
  
  .company-table-header,
  .company-data {
    grid-template-columns: 1.3fr 180px 1fr 40px;
  }
}

/* Add styles for the title section */
.title-section {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.title-byline {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin-left: 2px;
  background: linear-gradient(120deg, #bd93f9, #ff79c6);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* Simplified column titles */
.column-title {
  font-size: 1.1rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  font-family: 'Inter', sans-serif;
}

/* Simple table header */
.company-table-header {
  background: rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .column-title {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .column-title {
    font-size: 0.9rem;
  }
}

/* Add Batman-style branding */
.cphelper-brand {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 2px;
}

.batman-logo {
  color: #bd93f9;
  font-size: 2rem;
  filter: drop-shadow(0 0 10px rgba(189, 147, 249, 0.5));
}

.cphelper-text {
  font-family: 'Inter', sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 1px;
  color: #ffffff;
  text-transform: uppercase;
  text-shadow: 
    0 0 5px rgba(255, 0, 0, 0.3),
    0 0 10px rgba(255, 0, 0, 0.2);
  background: linear-gradient(180deg, #ffffff, #dddddd);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* Simplified column titles without dashes */
.column-title {
  font-size: 1.1rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  font-family: 'Inter', sans-serif;
}

/* Update table header */
.company-table-header {
  background: rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .cphelper-text {
    font-size: 1.1rem;
  }
  
  .batman-logo {
    font-size: 1.6rem;
  }

  .column-title {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .cphelper-text {
    font-size: 1rem;
  }
  
  .batman-logo {
    font-size: 1.2rem;
  }

  .column-title {
    font-size: 1rem;
  }
}

/* Add styles for problem statement */
.problem-statement {
    background: rgba(40, 42, 54, 0.8);
    border-radius: 8px;
    padding: 25px;
    margin-top: 15px;
    color: #f8f8f2;
    font-family: 'Inter', sans-serif;
    line-height: 1.6;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.problem-statement h2 {
    color: #bd93f9;
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
}

.problem-statement h3 {
    color: #ff79c6;
    font-size: 1.3rem;
    margin: 1.5rem 0 1rem;
    font-weight: 500;
}

.problem-statement p {
    margin-bottom: 1rem;
    color: #f8f8f2;
    font-size: 1rem;
}

.problem-statement code {
    background: rgba(68, 71, 90, 0.5);
    padding: 2px 6px;
    border-radius: 4px;
    font-family: 'Fira Code', monospace;
    font-size: 0.9em;
    color: #50fa7b;
}

.problem-statement pre {
    background: rgba(40, 42, 54, 0.9);
    padding: 15px;
    border-radius: 6px;
    margin: 1rem 0;
    overflow-x: auto;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.problem-statement pre.input,
.problem-statement pre.output {
    position: relative;
    padding-top: 30px;
}

.problem-statement pre.input::before,
.problem-statement pre.output::before {
    content: 'Input';
    position: absolute;
    top: 5px;
    left: 15px;
    font-size: 0.8rem;
    color: #6272a4;
    font-family: 'Inter', sans-serif;
}

.problem-statement pre.output::before {
    content: 'Output';
}

.problem-statement ul,
.problem-statement ol {
    margin: 1rem 0;
    padding-left: 1.5rem;
}

.problem-statement li {
    margin-bottom: 0.5rem;
}

.problem-statement .explanation {
    background: rgba(68, 71, 90, 0.2);
    border-left: 4px solid #ff79c6;
    padding: 15px;
    margin: 1rem 0;
    border-radius: 0 4px 4px 0;
}

/* Add responsive styles */
@media (max-width: 768px) {
    .problem-statement {
        padding: 15px;
    }

    .problem-statement h2 {
        font-size: 1.5rem;
    }

    .problem-statement h3 {
        font-size: 1.2rem;
    }

    .problem-statement p,
    .problem-statement li {
        font-size: 0.9rem;
    }

    .problem-statement code {
        font-size: 0.85em;
    }
}

.github-images-btn {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    background: #24292e;
    color: #fff;
    padding: 6px 12px;
    border-radius: 6px;
    font-size: 0.9rem;
    text-decoration: none;
    transition: all 0.3s ease;
    margin-left: 12px;
}

.github-images-btn:hover {
    background: #2f363d;
    transform: translateY(-1px);
    color: #fff;
    text-decoration: none;
}

.github-images-btn svg {
    font-size: 1.1rem;
}

/* Update question info layout */
.question-info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.question-title {
    margin: 0;
    flex: 1;
    min-width: 200px;
}

/* Add styles for the footer section */
.problem-footer {
    margin-top: 20px;
    padding-top: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: flex-end;
}

.footer-btn {
    background: rgba(80, 250, 123, 0.15);
    font-size: 0.85rem;
    padding: 8px 16px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .view-problem-btn {
        font-size: 0.8rem;
        padding: 5px 10px;
    }
    
    .footer-btn {
        font-size: 0.8rem;
        padding: 6px 12px;
    }
}

/* Add styles for the click indicator */
.question-title {
    margin: 0;
    flex: 1;
    min-width: 200px;
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
}

.click-indicator {
    display: flex;
    align-items: center;
    gap: 8px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.9rem;
}

.blink-arrow {
    animation: blink 2s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 0.5; }
  50% { opacity: 1; }
}


/* Hide the indicator when problem is expanded */
.question-item.expanded .click-indicator {
    display: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .click-text {
        display: none; /* Hide text on mobile, show only arrow */
    }
    
    .click-indicator {
        margin-left: 5px;
    }
}

/* Remove old spinner styles and add these simplified ones */
.simple-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
}

.spinner-dot {
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 50%;
    animation: simplePulse 1s ease-in-out infinite;
}

@keyframes simplePulse {
    0%, 100% {
        transform: scale(0.5);
        opacity: 0.5;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
}

/* Update button styles for loading state */
.solution-email-form button:disabled {
    background: var(--secondary-color);
    opacity: 0.9;
    cursor: wait;
    min-width: 90px;
    display: flex;
    justify-content: center;
}

.solution-email-form input:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

/* Add these media queries at the end of your CSS file */
@media (max-width: 768px) {
  .company-oa-title {
    font-size: 1.5rem;
  }

  .company-name-text {
    font-size: 0.9rem;
  }

  .company-icon-wrapper {
    display: none; /* Hide company icon on mobile */
  }

  .date-container {
    font-size: 0.75rem;
  }

  .question-title {
    font-size: 0.85rem;
  }

  .problem-content {
    font-size: 0.85rem;
  }

  .view-problem-btn,
  .get-solution-btn {
    font-size: 0.8rem;
    padding: 8px 12px;
  }

  .solution-email-form input {
    font-size: 0.85rem;
    padding: 8px 12px;
  }

  .solution-email-form button {
    font-size: 0.8rem;
    padding: 8px 12px;
  }

  .cphelper-text {
    font-size: 0.8rem;
  }
}

/* Even smaller screens */
@media (max-width: 480px) {
  .company-oa-title {
    font-size: 1.2rem;
  }

  .company-name-text {
    font-size: 0.85rem;
  }

  .date-container {
    font-size: 0.7rem;
  }

  .question-title {
    font-size: 0.8rem;
  }

  .problem-content {
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {
  .company-table-header,
  .company-data {
    display: grid;
    grid-template-columns: minmax(120px, 1fr) auto auto;  /* Removed questions column */
    gap: 15px;
    padding: 16px 30px;
  }

  /* Center align date column header and content */
  .company-table-header div:nth-child(2),
  .date-container {
    text-align: center;
    justify-self: center;
    padding: 0 25px;
  }

  /* Hide questions count column */
  .questions-count {
    display: none;
  }

  .company-name {
    grid-column: 1;
    padding-left: 25px;
  }

  .solution-button-container {
    grid-column: 3;
    padding-right: 25px;
    justify-self: end;
  }
}

@media (max-width: 480px) {
  .company-table-header,
  .company-data {
    padding: 14px 35px;
    gap: 12px;
  }

  .company-table-header div:nth-child(2),
  .date-container {
    padding: 0 30px;
  }
}

.desktop-only {
  display: block;
}

@media (max-width: 768px) {
  .desktop-only {
    display: none;
  }
}

.questions-count {
  font-size: 0.9rem;
  color: var(--text-primary);
  text-align: center;
  font-weight: 500;
}

/* Hide on mobile */
@media (max-width: 768px) {
  .questions-count {
    display: none;
  }
}

.edit-question-form {
    padding: 15px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    margin: 10px 0;
}

.edit-question-form form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.edit-question-form input,
.edit-question-form textarea {
    padding: 8px 12px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    color: white;
}

.edit-actions {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.save-btn,
.cancel-btn {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
}

.save-btn {
    background: #4CAF50;
    color: white;
}

.cancel-btn {
    background: #f44336;
    color: white;
}

.save-btn:hover {
    background: #45a049;
}

.cancel-btn:hover {
    background: #da190b;
}

.question-item {
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
  background: rgba(255, 255, 255, 0.05);
}

.edit-question-form {
  padding: 15px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}

.edit-question-form form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.edit-question-form input,
.edit-question-form textarea {
  padding: 8px 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.2);
  color: white;
}

.edit-actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.save-btn,
.cancel-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.save-btn {
  background: #4CAF50;
  color: white;
}

.cancel-btn {
  background: #f44336;
  color: white;
}

.edit-btn {
  padding: 6px 12px;
  background: #2196F3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-btn:hover {
  background: #1976D2;
}

.save-btn:hover {
  background: #45a049;
}

.cancel-btn:hover {
  background: #da190b;
}

/* Update the header section */
.header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0; /* Removed padding */
    background: none; /* Removed background */
    backdrop-filter: none; /* Removed blur */
    border: none; /* Removed border */
    margin-bottom: 20px;
    box-shadow: none; /* Removed shadow */
    gap: 20px;
    width: 100%;
    box-sizing: border-box;
}

.title-section {
    display: flex;
    flex-direction: column;
}

.title-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.cphelper-brand {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 5px;
}

.batman-logo {
    font-size: 1.2rem;
    color: #a8a4ff;
}

.by-text {
    font-size: 0.7rem;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.6);
    letter-spacing: 1px;
}

.cphelper-text {
    font-size: 0.9rem;
    font-weight: 700;
    background: linear-gradient(135deg, #6c5ce7, #a8a4ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    letter-spacing: 1px;
}

/* Responsive styles */
@media (max-width: 1024px) {
    .header-section {
        padding: 15px 20px;
        flex-wrap: wrap;
        gap: 15px;
    }

    .company-oa-title {
        font-size: 1.8rem;
    }

    .cphelper-brand {
        margin-top: 3px;
    }
}

@media (max-width: 768px) {
    .header-section {
        padding: 12px 15px;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 12px;
    }

    .title-wrapper {
        align-items: center;
    }

    .company-oa-title {
        font-size: 1.5rem;
    }

    .cphelper-brand {
        justify-content: center;
    }

    .kam-bar-container {
        width: 100%;
    }

    .get-help-button {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .company-oa-title {
        font-size: 1.3rem;
    }

    .cphelper-text {
        font-size: 0.8rem;
    }

    .by-text {
        font-size: 0.6rem;
    }
}

/* Update header section layout */
.header-section {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 25px 30px;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    width: 100%;
    box-sizing: border-box;
}

/* Top section for title and help button */
.header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 20px;
}

/* Update kam bar styles */






/* Remove all other header-related styles */

/* Add/update these mobile-specific styles for question actions */
@media (max-width: 768px) {
    .question-preview {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 15px;
    }

    .question-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .question-title {
        width: 100%;
        margin-bottom: 5px;
    }

    .question-actions {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-left: 5px; /* Reduced margin */
        width: 100%;
    }

    .view-problem-btn,
    .get-solution-btn {
        width: 100%;
        justify-content: center;
        padding: 8px 12px;
        font-size: 0.85rem;
        margin: 0;
    }

    .solution-button-container {
        width: 100%;
    }

    .solution-email-form {
        width: 100%;
    }

    .solution-form-content {
        flex-direction: column;
        width: 100%;
    }

    .solution-form-content input,
    .solution-form-content button {
        width: 100%;
        margin: 0;
    }

    .question-preview {
        padding: 12px;
    }

    .question-info {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .question-title {
        width: 100%;
        padding-right: 0;
    }
}

/* Additional adjustments for even smaller screens */
@media (max-width: 480px) {
    .question-actions {
        gap: 6px;
        margin-left: 3px;
    }

    .get-solution-btn,
    .view-problem-btn {
        padding: 8px 10px;
        font-size: 0.8rem;
    }

    .solution-form-content input,
    .solution-form-content button {
        padding: 8px;
        font-size: 0.85rem;
    }

    .solution-email-form {
        padding: 8px;
    }
}

@keyframes spin {
    0%, 100% { opacity: 0.2; }
    50% { opacity: 1; }
}

/* Update mobile-specific styles for date container */
@media (max-width: 768px) {
    .date-container {
        margin-left: 0;
        min-width: auto;
        padding: 0;
        background: none;
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
        border: none;
        box-shadow: none;
        font-size: 0.75rem;
        gap: 4px;
        color: rgba(255, 255, 255, 0.8);
    }

    .date-ago {
        font-size: 0.7rem;
        color: rgba(255, 255, 255, 0.6);
    }

    /* Remove hover effects on mobile */
    .company-data:hover .date-container {
        background: none;
        border: none;
        transform: none;
        box-shadow: none;
    }
}

@media (max-width: 480px) {
    .date-container {
        font-size: 0.7rem;
    }

    .date-ago {
        font-size: 0.65rem;
    }
}

/* Add this to your existing mobile media queries */
@media (max-width: 768px) {
    .header-top {
        flex-direction: column;
        gap: 15px;
        text-align: center;
    }

    .get-help-button {
        display: none; /* Hide the button on mobile */
    }

    .title-wrapper {
        width: 100%;
        justify-content: center;
    }
}

/* Update the header top layout for better spacing */
.header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 20px;
}

.error-message {
    background: rgba(255, 0, 0, 0.1);
    border: 1px solid rgba(255, 0, 0, 0.2);
    color: #fff;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
    margin: 1rem 0;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    color: #fff;
    font-size: 1.2rem;
}

/* Update kam container styles */
.kam-bar-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 10px 0;
}

/* Add clear button styles */
.clear-kam {
  position: absolute;
  right: 45px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease;
}

.clear-kam:hover {
  color: rgba(255, 255, 255, 0.9);
}

/* Update kam input to accommodate clear button */
.kam-input {
  padding-right: 75px; /* Space for both icons */
}

/* Add highlight for matching text */
.highlight-match {
  background: rgba(108, 92, 231, 0.2);
  padding: 0 2px;
  border-radius: 3px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .kam-bar-container {
    margin: 5px 0;
  }
  
  .clear-kam {
    right: 40px;
  }
  
  .kam-input {
    padding-right: 70px;
  }
}

/* Floating Contribute Button */
.floating-contribute-btn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background: linear-gradient(135deg, #6c5ce7, #a8a4ff);
  color: white;
  border: none;
  border-radius: 50px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(108, 92, 231, 0.3);
  transition: all 0.3s ease;
  z-index: 1000;
  font-weight: 600;
  font-size: 1rem;
}

.floating-contribute-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(108, 92, 231, 0.4);
}

.contribute-icon {
  font-size: 1.2rem;
}

/* Contribute Modal */
.contribute-modal {
  background: #282a36;
  padding: 30px;
  border-radius: 15px;
  max-width: 600px;
  width: 90%;
  position: relative;
  color: white;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  animation: modalSlideUp 0.3s ease-out;
}

.contribute-modal h2 {
  margin: 0 0 25px;
  font-size: 1.8rem;
  background: linear-gradient(135deg, #fff, #a8a4ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.contribute-options {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.contribute-option {
  background: rgba(255, 255, 255, 0.05);
  padding: 20px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.contribute-option h3 {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 0 15px;
  color: #bd93f9;
}

.option-icon {
  font-size: 1.4rem;
}

.contribute-divider {
  text-align: center;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.6);
  position: relative;
  margin: 10px 0;
}

.github-link,
.email-link {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  text-decoration: none;
  margin-top: 15px;
  transition: all 0.3s ease;
}

.github-link:hover,
.email-link:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-1px);
}

.contribute-footer {
  margin-top: 25px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.contribute-footer p {
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
}

.lock-icon {
  color: #50fa7b;
}

@keyframes modalSlideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .floating-contribute-btn {
    bottom: 20px;
    right: 20px;
    padding: 10px 20px;
    font-size: 0.9rem;
  }

  .contribute-modal {
    padding: 20px;
  }

  .contribute-modal h2 {
    font-size: 1.5rem;
  }

  .contribute-option {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .floating-contribute-btn span {
    display: none;
  }

  .floating-contribute-btn {
    padding: 12px;
    border-radius: 50%;
  }

  .contribute-icon {
    margin: 0;
  }
}

/* Update modal styles */
.contribute-modal {
  background: #282a36;
  padding: 30px;
  border-radius: 15px;
  max-width: 600px;
  width: 90%;
  position: relative;
  color: white;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  animation: modalSlideUp 0.3s ease-out;
}

/* Close button */
.modal-close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  z-index: 1000;
  padding: 0;
}

.modal-close-btn:hover {
  background: rgba(255, 85, 85, 0.2);
  border-color: #ff5555;
  transform: rotate(90deg);
}

.modal-close-btn:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 85, 85, 0.4);
  border-color: #ff5555;
}

.modal-close-btn:active {
  transform: rotate(90deg) scale(0.95);
}

.close-icon {
  color: rgba(255, 255, 255, 0.9);
  font-size: 28px;
  font-weight: 300;
  line-height: 1;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.modal-close-btn:hover .close-icon {
  color: #ff5555;
}

/* Screen reader only text */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* Add focus outline for keyboard navigation */
.modal-close-btn:focus-visible {
  outline: 3px solid #ff5555;
  outline-offset: 2px;
}

/* Increase contrast for better visibility */
@media (prefers-contrast: more) {
  .modal-close-btn {
    background: rgba(255, 255, 255, 0.2);
    border-color: rgba(255, 255, 255, 0.4);
  }

  .close-icon {
    color: #ffffff;
  }
}

  /* Reduce motion if user prefers */
  @media (prefers-reduced-motion: reduce) {
    .modal-close-btn {
      transition: none;
    }
    
    .modal-close-btn:hover {
      transform: none;
    }
  }



/* Header with earn badge */
.contribute-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  padding-right: 40px;
}

.contribute-header h2 {
  margin: 0;
  font-size: 1.8rem;
  background: linear-gradient(135deg, #fff, #a8a4ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.earn-badge {
  background: linear-gradient(135deg, #50fa7b, #38E54D);
  color: #282a36;
  padding: 8px 15px;
  border-radius: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 0.9rem;
  box-shadow: 0 2px 8px rgba(80, 250, 123, 0.3);
}

.earn-icon {
  font-size: 1rem;
}

/* Reward notes */
.reward-note {
  margin-top: 12px;
  background: rgba(80, 250, 123, 0.1);
  padding: 10px 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #50fa7b;
  font-size: 0.9rem;
  font-weight: 500;
}

.reward-icon {
  font-size: 1rem;
}

/* Updated footer */
.contribute-footer {
  margin-top: 25px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-content {
  display: flex;
  align-items: center;
  gap: 15px;
  background: rgba(255, 255, 255, 0.05);
  padding: 15px;
  border-radius: 12px;
}

.support-icon {
  font-size: 1.8rem;
  color: #bd93f9;
}

.footer-content h4 {
  margin: 0;
  color: #fff;
}

.footer-content p {
  margin: 5px 0 0;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
}

.telegram-link {
  margin-left: auto;
  background: #0088cc;
  color: white;
  padding: 8px 15px;
  border-radius: 8px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.telegram-link:hover {
  background: #0099dd;
  transform: translateY(-1px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .contribute-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .earn-badge {
    align-self: flex-end;
  }

  .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .telegram-link {
    margin: 15px auto 0;
  }
}

/* Keep existing modal styles and add mobile-specific updates */
@media (max-width: 768px) {
  .contribute-modal {
    width: 95%;
    padding: 20px;
    margin: 15px;
    max-height: 90vh;
    overflow-y: auto;
  }

  /* Enhanced close button for mobile */
  .modal-close-btn {
    top: 12px;
    right: 12px;
    width: 44px;
    height: 44px;
    background: rgba(255, 85, 85, 0.15);
    border: 2px solid rgba(255, 85, 85, 0.3);
    z-index: 1001;
  }

  .close-icon {
    font-size: 24px;
    color: #ff5555;
  }

  /* Adjust header spacing for mobile */
  .contribute-header {
    padding-top: 15px;
    margin-top: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .contribute-header h2 {
    font-size: 1.5rem;
  }

  /* Adjust options layout for mobile */
  .contribute-options {
    gap: 20px;
  }

  .contribute-option {
    padding: 15px;
  }

  .contribute-divider {
    margin: 10px 0;
  }

  /* Adjust footer for mobile */
  .contribute-footer {
    margin-top: 20px;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 12px;
  }

  .telegram-link {
    width: 100%;
    justify-content: center;
  }
}

/* Additional adjustments for even smaller screens */
@media (max-width: 480px) {
  .contribute-modal {
    padding: 15px;
  }

  .modal-close-btn {
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
  }

  .contribute-header h2 {
    font-size: 1.3rem;
  }

  .contribute-option h3 {
    font-size: 1.1rem;
  }
}

/* Mobile-specific modal styles */
@media (max-width: 768px) {
  .modal-overlay {
    padding: 40px 15px; /* Add padding to overlay */
    display: flex;
    align-items: flex-start; /* Change from center to flex-start */
    justify-content: center;
  }

  .contribute-modal {
    width: 95%;
    padding: 20px;
    margin-top: 50px; /* Increased top margin */
    max-height: calc(100vh - 120px); /* Adjust max height to account for top margin */
    overflow-y: auto;
    position: relative;
  }

  /* Enhanced close button positioning */
  .modal-close-btn {
    position: fixed; /* Change to fixed positioning */
    top: 75px; /* Position relative to viewport */
    right: 25px;
    width: 44px;
    height: 44px;
    background: rgba(255, 85, 85, 0.15);
    border: 2px solid rgba(255, 85, 85, 0.3);
    z-index: 1001;
    backdrop-filter: blur(4px);
  }

  .close-icon {
    font-size: 24px;
    color: #ff5555;
    font-weight: bold;
  }
}

/* Even smaller screens */
@media (max-width: 480px) {
  .contribute-modal {
    margin-top: 50px;
    padding: 15px;
  }

  .modal-close-btn {
    top: 65px;
    right: 20px;
  }
}

/* Update modal overlay and modal positioning */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Change from center to flex-start */
  padding: 100px 20px 40px; /* Increased top padding */
  z-index: 1000;
  backdrop-filter: blur(8px);
}

.contribute-modal {
  background: #282a36;
  padding: 30px;
  border-radius: 15px;
  max-width: 600px;
  width: 90%;
  position: relative;
  color: white;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  animation: modalSlideUp 0.3s ease-out;
  margin-top: 20px; /* Add default margin top */
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .modal-overlay {
    padding: 80px 15px 40px; /* Slightly reduced top padding for mobile */
  }

  .contribute-modal {
    width: 95%;
    padding: 20px;
    margin-top: 20px; /* Keep consistent margin */
  }

  .modal-close-btn {
    top: 85px; /* Adjusted for new positioning */
    right: 20px;
  }
}

/* Even smaller screens */
@media (max-width: 480px) {
  .modal-overlay {
    padding: 70px 15px 40px; /* Further reduced top padding */
  }

  .contribute-modal {
    margin-top: 20px; /* Keep consistent margin */
  }

  .modal-close-btn {
    top: 75px; /* Adjusted for new positioning */
  }
}

/* Add these mobile-specific margin adjustments */
@media (max-width: 768px) {
  .company-oa-wrapper {
    margin-top: 60px;  /* Large top margin for tablets */
  }

  .company-table {
    margin-left: -40px;  /* Increased negative margin */
    margin-right: -40px; /* Balance the layout */
    width: calc(100% + 80px);  /* Compensate for negative margins */
  }
}

/* Even smaller screens */
@media (max-width: 480px) {
  .company-oa-wrapper {
    margin-top: 80px;  /* Even larger top margin for phones */
  }

  .company-table {
    margin-left: -50px;  /* Further increased negative margin */
    margin-right: -50px; /* Balance the layout */
    width: calc(100% + 100px);  /* Compensate for negative margins */
  }
}

/* Extra small screens */
@media (max-width: 360px) {
  .company-table {
    margin-left: -60px;  /* Maximum negative margin */
    margin-right: -60px;
    width: calc(100% + 120px);
  }
}

/* Replace the old loading-spinner styles with these new ones */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  gap: 20px;
}

.loading-spinner {
  position: relative;
  width: 80px;
  height: 80px;
  animation: pulse 1.5s ease-in-out infinite;
}

.loading-spinner::before,
.loading-spinner::after {
  content: '';
  position: absolute;
  border: 4px solid transparent;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  mix-blend-mode: screen;
}

.loading-spinner::before {
  border-top-color: #bd93f9;
  border-right-color: #bd93f9;
  animation: spin 2s linear infinite;
}

.loading-spinner::after {
  border-bottom-color: #ff79c6;
  border-left-color: #ff79c6;
  animation: spin 2s linear infinite reverse;
}

.loading-text {
  font-family: 'Inter', sans-serif;
  font-size: 2.9rem;
  color: #f8f8f2;
  text-align: center;
  opacity: 0.9;
  letter-spacing: 1px;
  animation: fadeInOut 1.5s ease-in-out infinite;
}

.loading-subtext {
  font-size: 2rem;
  color: rgba(248, 248, 242, 0.6);
  text-align: center;
  max-width: 300px;
  line-height: 1.4;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
}

/* Add these styles for the loading logo */
.loading-logo {
  font-size: 2rem;
  color: #bd93f9;
  margin-bottom: 15px;
  animation: bounce 2s ease-in-out infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Replace the existing loading styles with these new Batman-themed styles */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  gap: 30px;
  position: relative;
}

/* Batman logo animation */
.loading-logo-container {
  position: relative;
  width: 120px;
  height: 120px;
}

.loading-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  color: #1a1a1a;
  filter: drop-shadow(0 0 10px rgba(189, 147, 249, 0.5));
  animation: batSignal 3s ease-in-out infinite;
}

.logo-spotlight {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 140px;
  height: 140px;
  background: radial-gradient(
    circle,
    rgba(189, 147, 249, 0.2) 0%,
    rgba(189, 147, 249, 0.1) 40%,
    transparent 70%
  );
  border-radius: 50%;
  animation: spotlight 3s ease-in-out infinite;
}

.bat-shadow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  background: rgba(0, 0, 0, 0.3);
  filter: blur(15px);
  border-radius: 50%;
  animation: shadowPulse 3s ease-in-out infinite;
}

.loading-text {
  font-family: 'Inter', sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  color: #f8f8f2;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  animation: textGlow 3s ease-in-out infinite;
  position: relative;
}

.loading-subtext {
  font-size: 0.9rem;
  color: rgba(248, 248, 242, 0.6);
  text-align: center;
  max-width: 300px;
  line-height: 1.6;
  animation: fadeInOut 3s ease-in-out infinite;
}

/* Flying bats animation */
.flying-bats {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.bat {
  position: absolute;
  font-size: 1rem;
  color: rgba(189, 147, 249, 0.3);
  animation: flyAcross 4s linear infinite;
}

/* Keyframes */
@keyframes batSignal {
  0%, 100% {
    transform: translate(-50%, -50%) scale(1);
    color: #1a1a1a;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1);
    color: #bd93f9;
  }
}

@keyframes spotlight {
  0%, 100% {
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2);
  }
}

@keyframes shadowPulse {
  0%, 100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.3;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
    opacity: 0.5;
  }
}

@keyframes textGlow {
  0%, 100% {
    text-shadow: 0 0 10px rgba(189, 147, 249, 0.3);
    letter-spacing: 2px;
  }
  50% {
    text-shadow: 0 0 20px rgba(189, 147, 249, 0.6);
    letter-spacing: 3px;
  }
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.8;
  }
}

@keyframes flyAcross {
  0% {
    transform: translate(-100%, random(100) * 1%);
  }
  100% {
    transform: translate(100vw, random(100) * 1%);
  }
}

/* Add initial states and delays */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  gap: 30px;
  position: relative;
  /* Remove the background color */
  opacity: 0;
  animation: fadeIn 0.5s ease-out 0.2s forwards;
  z-index: 10;
}

/* Spotlight entrance */
.logo-spotlight {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 140px;
  height: 140px;
  background: radial-gradient(
    circle,
    rgba(189, 147, 249, 0.15) 0%,
    rgba(189, 147, 249, 0.05) 40%,
    transparent 70%
  );
  border-radius: 50%;
  opacity: 0;
  animation: 
    spotlightEnter 1s ease-out 0.5s forwards,
    spotlight 3s ease-in-out 1.5s infinite;
}

/* Batman logo entrance */
.loading-logo-container {
  position: relative;
  width: 120px;
  height: 120px;
  opacity: 0;
  transform: scale(0.5);
  animation: logoEnter 1s ease-out 1s forwards;
}

.loading-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  color: #1a1a1a;
  filter: drop-shadow(0 0 10px rgba(189, 147, 249, 0.5));
  animation: batSignal 3s ease-in-out 2s infinite;
}

/* Text entrance animations */
.loading-text {
  opacity: 0;
  transform: translateY(20px);
  animation: 
    textEnter 0.8s ease-out 1.5s forwards,
    textGlow 3s ease-in-out 2s infinite;
}

.loading-subtext {
  opacity: 0;
  transform: translateY(20px);
  animation: 
    textEnter 0.8s ease-out 1.8s forwards,
    fadeInOut 3s ease-in-out 2s infinite;
}

/* Flying bats entrance */
.flying-bats {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  animation: fadeIn 0.5s ease-out 2s forwards;
}

.bat {
  position: absolute;
  opacity: 0;
  animation: 
    fadeIn 0.5s ease-out forwards,
    flyAcross 4s linear infinite;
}

/* New entrance animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes spotlightEnter {
  from {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }
  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

@keyframes logoEnter {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes textEnter {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Update existing animations */
@keyframes batSignal {
  0% {
    transform: translate(-50%, -50%) scale(1);
    color: #1a1a1a;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1);
    color: #bd93f9;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    color: #1a1a1a;
  }
}

@keyframes spotlight {
  0% {
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes flyAcross {
  0% {
    transform: translate(-100%, 0) rotate(0deg);
  }
  100% {
    transform: translate(100vw, 0) rotate(360deg);
  }
}

/* Update loading container styles */
.loading-container.dark {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  gap: 30px;
  position: relative;
  background: rgba(0, 0, 0, 0.95);
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
}

/* Dramatic spotlight effect */
.logo-spotlight {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  background: radial-gradient(
    circle,
    rgba(255, 0, 0, 0.15) 0%,
    rgba(255, 0, 0, 0.05) 40%,
    transparent 70%
  );
  border-radius: 50%;
  animation: spotlight 4s ease-in-out infinite;
  filter: blur(10px);
}

/* Batman logo */
.loading-logo-container {
  position: relative;
  width: 120px;
  height: 120px;
  opacity: 0;
  transform: scale(0.5);
  animation: logoEnter 1s ease-out forwards;
}

.loading-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 5rem;
  color: #1a1a1a;
  filter: drop-shadow(0 0 15px rgba(255, 0, 0, 0.7));
  animation: batSignal 4s ease-in-out infinite;
}

/* Text styles */
.loading-text {
  font-family: 'Inter', sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  color: #f8f8f2;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  opacity: 0;
  animation: 
    textEnter 0.8s ease-out 0.5s forwards,
    textGlow 4s ease-in-out infinite;
}

.loading-subtext {
  font-size: 1.1rem;
  color: rgba(255, 0, 0, 0.8);
  text-align: center;
  max-width: 500px;
  line-height: 1.6;
  opacity: 0;
  font-weight: 500;
  font-style: italic;
  animation: 
    textEnter 0.8s ease-out 1s forwards,
    subtextPulse 4s ease-in-out infinite;
}

/* Animations */
@keyframes batSignal {
  0%, 100% {
    transform: translate(-50%, -50%) scale(1);
    color: #1a1a1a;
    filter: drop-shadow(0 0 15px rgba(255, 0, 0, 0.7));
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
    color: #300;
    filter: drop-shadow(0 0 25px rgba(255, 0, 0, 0.9));
  }
}

@keyframes textGlow {
  0%, 100% {
    text-shadow: 0 0 10px rgba(255, 0, 0, 0.3);
    letter-spacing: 2px;
  }
  50% {
    text-shadow: 0 0 20px rgba(255, 0, 0, 0.6);
    letter-spacing: 3px;
  }
}

@keyframes subtextPulse {
  0%, 100% {
    opacity: 0.6;
    transform: scale(1);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.02);
  }
}

@keyframes textEnter {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spotlight {
  0%, 100% {
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    opacity: 0.8;
    transform: translate(-50%, -50%) scale(1.3);
  }
}

/* Add styles for the tagline */
.header-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.tagline {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  color: #bd93f9;
  letter-spacing: 0.5px;
  padding: 10px 0;
  background: linear-gradient(135deg, rgba(189, 147, 249, 0.1), rgba(189, 147, 249, 0.05));
  border-radius: 8px;
  margin-bottom: 10px;
  animation: fadeIn 0.5s ease-out;
}

/* Update loading styles */
.loading-container.dark {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  gap: 30px;
  position: relative;
  background: rgba(0, 0, 0, 0.95);
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
}

.loading-text {
  font-family: 'Inter', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  color: #f8f8f2;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  animation: textGlow 3s ease-in-out infinite;
  margin-top: 40px;
}

.loading-subtext {
  font-size: 1.2rem;
  color: rgba(255, 0, 0, 0.8);
  text-align: center;
  max-width: 500px;
  line-height: 1.6;
  animation: fadeInOut 3s ease-in-out infinite;
  font-weight: 500;
  font-style: italic;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .tagline {
    font-size: 1.2rem;
    padding: 8px 15px;
  }

  .loading-text {
    font-size: 1.6rem;
  }

  .loading-subtext {
    font-size: 1rem;
    padding: 0 20px;
  }
}

@media (max-width: 480px) {
  .tagline {
    font-size: 1rem;
    padding: 6px 10px;
  }

  .loading-text {
    font-size: 1.4rem;
  }

  .loading-subtext {
    font-size: 0.9rem;
  }
}

/* Add highlight styles for the O and A letters */
.highlight-letter {
  color: #bd93f9;
  font-weight: 800;
  font-size: 1.2em;
  text-shadow: 
    0 0 10px rgba(189, 147, 249, 0.8),
    0 0 20px rgba(189, 147, 249, 0.4);
  animation: letterPulse 3s ease-in-out infinite;
}

@keyframes letterPulse {
  0%, 100% {
    text-shadow: 0 0 10px rgba(189, 147, 249, 0.5);
    transform: scale(1);
  }
  50% {
    text-shadow: 0 0 15px rgba(189, 147, 249, 0.8);
    transform: scale(1.05);
  }
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #282a36;
  padding: 20px;
}

.loading-logo-container {
  margin-bottom: 24px;
}

.loading-logo {
  font-size: 120px;
  color: #bd93f9;
  animation: float 2s ease-in-out infinite;
}

.loading-text {
  font-size: 24px;
  font-weight: bold;
  color: #f8f8f2;
  margin-bottom: 12px;
  letter-spacing: 2px;
}

.loading-subtext {
  font-size: 16px;
  color: #6272a4;
  text-align: center;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .loading-logo {
    font-size: 100px;
  }
  
  .loading-text {
    font-size: 20px;
  }
  
  .loading-subtext {
    font-size: 14px;
  }
}

/* Update problem statement styles */
.problem-container {
  background: rgba(40, 42, 54, 0.8);
  border-radius: 8px;
  padding: 25px;
  color: #f8f8f2;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  line-height: 1.6;
}

.problem-description {
  margin-bottom: 20px;
}

.section {
  margin: 20px 0;
  padding: 15px;
  background: rgba(68, 71, 90, 0.2);
  border-radius: 6px;
}

.section-title {
  color: #bd93f9;
  font-size: 1.4rem;
  margin-bottom: 15px;
  font-weight: 600;
}

code {
  background: rgba(40, 42, 54, 0.9);
  color: #50fa7b;
  padding: 2px 6px;
  border-radius: 4px;
  font-family: 'Fira Code', monospace;
  font-size: 0.9em;
}

br {
  margin-bottom: 10px;
  content: "";
  display: block;
}

/* Example section specific styles */
.example {
  background: rgba(40, 42, 54, 0.9);
  padding: 15px;
  border-radius: 6px;
  margin: 15px 0;
  border: 1px solid rgba(189, 147, 249, 0.2);
}

/* Input/Output specific styles */
pre {
  background: rgba(40, 42, 54, 0.95);
  padding: 12px;
  border-radius: 4px;
  overflow-x: auto;
  font-family: 'Fira Code', monospace;
  color: #50fa7b;
  margin: 10px 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .problem-container {
    padding: 15px;
  }

  .section-title {
    font-size: 1.2rem;
  }

  code {
    font-size: 0.85em;
  }
}

/* Replace all existing loading-related styles with these new ones */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  background: transparent;
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.loading-logo-container {
  display: flex;
  align-items: baseline;
  gap: 5px;
}

.loading-text {
  font-size: 3rem;
  font-weight: 800;
  color: #bd93f9;
  text-shadow: 0 0 10px rgba(189, 147, 249, 0.5);
  animation: pulse 1.5s ease-in-out infinite;
}

.loading-text-helper {
  font-size: 2rem;
  font-weight: 600;
  color: #f8f8f2;
  opacity: 0.8;
}

.loading-dots {
  display: flex;
  gap: 8px;
}

.loading-dots span {
  width: 8px;
  height: 8px;
  background-color: #bd93f9;
  border-radius: 50%;
  animation: dots 1.5s ease-in-out infinite;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
}

@keyframes dots {
  0%, 100% {
    transform: translateY(0);
    opacity: 0.5;
  }
  50% {
    transform: translateY(-10px);
    opacity: 1;
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .loading-text {
    font-size: 2.5rem;
  }
  
  .loading-text-helper {
    font-size: 1.5rem;
  }
  
  .loading-dots span {
    width: 6px;
    height: 6px;
  }
}

/* Replace existing loading styles with these new ones */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #000000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.loading-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-text {
  font-size: 4.5rem;
  font-weight: 800;
  color: #bd93f9;
  text-shadow: 0 0 20px rgba(189, 147, 249, 0.5);
  animation: pulse 1.5s ease-in-out infinite;
  letter-spacing: 2px;
}

.loading-dots {
  display: flex;
  gap: 12px;
}

.loading-dots span {
  width: 10px;
  height: 10px;
  background-color: #bd93f9;
  border-radius: 50%;
  animation: dots 1.5s ease-in-out infinite;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.8;
  }
}

@keyframes dots {
  0%, 100% {
    transform: translateY(0);
    opacity: 0.5;
  }
  50% {
    transform: translateY(-15px);
    opacity: 1;
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .loading-text {
    font-size: 3rem;
  }
  
  .loading-dots span {
    width: 8px;
    height: 8px;
  }
}

@media (max-width: 480px) {
  .loading-text {
    font-size: 2.5rem;
  }
  
  .loading-dots span {
    width: 6px;
    height: 6px;
  }
}

/* Replace existing loading styles with these enhanced ones */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #000000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  transform: translateY(-5%);
}

.loading-logo-container {
  display: flex;
  align-items: baseline;
  gap: 15px;
}

.loading-text {
  font-size: 8rem;
  font-weight: 900;
  color: #bd93f9;
  text-shadow: 0 0 30px rgba(189, 147, 249, 0.5),
               0 0 60px rgba(189, 147, 249, 0.3),
               0 0 90px rgba(189, 147, 249, 0.2);
  animation: textGlow 2s ease-in-out infinite;
  letter-spacing: 2px;
  background: linear-gradient(135deg, #bd93f9, #ff79c6);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.loading-text-helper {
  font-size: 6rem;
  font-weight: 800;
  color: #f8f8f2;
  opacity: 0.9;
  text-shadow: 0 0 20px rgba(248, 248, 242, 0.4),
               0 0 40px rgba(248, 248, 242, 0.2);
  animation: helperGlow 2s ease-in-out infinite alternate;
  letter-spacing: 3px;
  background: linear-gradient(135deg, #f8f8f2, #6272a4);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.loading-dots {
  display: flex;
  gap: 20px;
}

.loading-dots span {
  width: 15px;
  height: 15px;
  background: linear-gradient(135deg, #bd93f9, #ff79c6);
  border-radius: 50%;
  animation: dotsFloat 1.5s ease-in-out infinite;
  box-shadow: 0 0 20px rgba(189, 147, 249, 0.5);
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
  background: linear-gradient(135deg, #ff79c6, #bd93f9);
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
  background: linear-gradient(135deg, #bd93f9, #ff79c6);
}

@keyframes textGlow {
  0%, 100% {
    opacity: 1;
    transform: scale(1);
    filter: brightness(1);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.02);
    filter: brightness(1.2);
  }
}

@keyframes helperGlow {
  0% {
    opacity: 0.7;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1.02);
  }
}

@keyframes dotsFloat {
  0%, 100% {
    transform: translateY(0);
    opacity: 0.5;
  }
  50% {
    transform: translateY(-20px);
    opacity: 1;
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .loading-text {
    font-size: 5rem;
  }
  
  .loading-text-helper {
    font-size: 3.5rem;
  }
  
  .loading-dots span {
    width: 12px;
    height: 12px;
  }
}

@media (max-width: 480px) {
  .loading-text {
    font-size: 3.5rem;
  }
  
  .loading-text-helper {
    font-size: 2.5rem;
  }
  
  .loading-dots span {
    width: 10px;
    height: 10px;
  }
}

/* Replace existing loading styles with these enhanced ones */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #000000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  transform: translateY(-10%);
  position: relative;
}

.loading-logo-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-text-wrapper {
  display: flex;
  align-items: baseline;
  gap: 20px;
  position: relative;
  z-index: 2;
}

.loading-text-oa {
  font-size: 8rem;
  font-weight: 900;
  background: linear-gradient(135deg, #bd93f9 0%, #ff79c6 50%, #bd93f9 100%);
  background-size: 200% auto;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientFlow 4s linear infinite,
             textFloat 3s ease-in-out infinite;
  text-shadow: 0 0 60px rgba(189, 147, 249, 0.3);
  letter-spacing: 4px;
}

.loading-text-helper {
  font-size: 8rem;
  font-weight: 800;
  background: linear-gradient(135deg, #f8f8f2 0%, #6272a4 50%, #f8f8f2 100%);
  background-size: 200% auto;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientFlow 4s linear infinite reverse,
             textFloat 3s ease-in-out infinite;
  animation-delay: 0.2s;
  letter-spacing: 2px;
}

.loading-glow {
  position: absolute;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle,
    rgba(189, 147, 249, 0.1) 0%,
    rgba(189, 147, 249, 0.05) 30%,
    transparent 70%
  );
  filter: blur(20px);
  animation: rotate 10s linear infinite;
  z-index: 1;
}

.loading-dots {
  display: flex;
  gap: 25px;
  margin-top: 20px;
}

.loading-dots span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: linear-gradient(135deg, #bd93f9, #ff79c6);
  animation: dotsFloat 2s ease-in-out infinite;
  box-shadow: 0 0 30px rgba(189, 147, 249, 0.5);
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
  background: linear-gradient(135deg, #ff79c6, #bd93f9);
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
  background: linear-gradient(135deg, #bd93f9, #ff79c6);
}

@keyframes gradientFlow {
  0% { background-position: 0% 50%; }
  100% { background-position: 200% 50%; }
}

@keyframes textFloat {
  0%, 100% {
    transform: translateY(0) rotate(-2deg);
  }
  50% {
    transform: translateY(-20px) rotate(2deg);
  }
}

@keyframes rotate {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}

@keyframes dotsFloat {
  0%, 100% {
    transform: translateY(0) scale(1);
    opacity: 0.5;
  }
  50% {
    transform: translateY(-30px) scale(1.2);
    opacity: 1;
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .loading-text {
    font-size: 5rem;
  }
  
  .loading-text-helper {
    font-size: 3.5rem;
  }
  
  .loading-dots span {
    width: 12px;
    height: 12px;
  }
}

@media (max-width: 480px) {
  .loading-text {
    font-size: 3.5rem;
  }
  
  .loading-text-helper {
    font-size: 2.5rem;
  }
  
  .loading-dots span {
    width: 10px;
    height: 10px;
  }
}

/* Add these root variables at the top */
:root {
  --glass-bg-dark: rgba(0, 0, 0, 0.7);
  --glass-bg-light: rgba(255, 255, 255, 0.08);
  --glass-border: 1px solid rgba(255, 255, 255, 0.1);
  --glass-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  --text-primary: rgba(255, 255, 255, 0.9);
  --text-secondary: rgba(255, 255, 255, 0.6);
  --hover-bg: rgba(255, 255, 255, 0.12);
}

.company-oa-container {
  background: var(--glass-bg-dark);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: var(--glass-border);
  box-shadow: var(--glass-shadow);
  border-radius: 20px;
  padding: 30px;
  margin: 20px auto;
  max-width: 1200px;
}

.company-row {
  background: var(--glass-bg-light);
  border: var(--glass-border);
  border-radius: 15px;
  margin-bottom: 15px;
  transition: all 0.3s ease;
}

.company-row:hover {
  background: var(--hover-bg);
  transform: translateY(-2px);
  box-shadow: var(--glass-shadow);
}

.company-data {
  padding: 20px;
  display: grid;
  grid-template-columns: 2fr 180px 80px 120px 50px; /* Adjusted column widths */
  gap: 20px; /* Reduced gap */
  align-items: center;
}

.company-icon-wrapper {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: var(--glass-border);
  border-radius: 12px;
}

.date-container {
  background: var(--glass-bg-light);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: var(--glass-border);
}

/* Update problem statement styles */
.problem-statement {
  background: var(--glass-bg-dark);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border: var(--glass-border);
  box-shadow: var(--glass-shadow);
}

/* Update difficulty badges */
.difficulty-badge {
  background: var(--glass-bg-light);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: var(--glass-border);
  padding: 6px 12px;
  border-radius: 20px;
}

/* Update solution button */
.show-solution-btn {
  background: var(--glass-bg-light);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: var(--glass-border);
  color: var(--text-primary);
  transition: all 0.3s ease;
}

.show-solution-btn:hover {
  background: var(--hover-bg);
  transform: translateY(-2px);
  box-shadow: var(--glass-shadow);
}

/* Update kam bar */
.kam-input {
  background: var(--glass-bg-light);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: var(--glass-border);
  color: var(--text-primary);
}

.kam-input:focus {
  background: var(--hover-bg);
  border-color: rgba(255, 255, 255, 0.2);
}

/* Add hover effects */
.company-data:hover .company-icon-wrapper,
.company-data:hover .date-container {
  border-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

/* Update table header */
.company-table-header {
  background: var(--glass-bg-dark);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: var(--glass-border);
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 20px;
}

/* Update scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--glass-bg-dark);
}

::-webkit-scrollbar-thumb {
  background: var(--glass-bg-light);
  border-radius: 5px;
  border: 2px solid var(--glass-bg-dark);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--hover-bg);
}

/* Company row styles */
.company-row {
  background: rgba(32, 32, 36, 0.6);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 20px;
  margin-bottom: 15px;
  padding: 15px;
  transition: all 0.3s ease;
}

/* Company header section */
.company-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: rgba(28, 28, 32, 0.8);
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.06);
}

.company-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.company-icon-wrapper {
  width: 40px;
  height: 40px;
  background: rgba(20, 20, 24, 0.8);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.06);
}

.company-name-text {
  font-size: 1.2rem;
  font-weight: 500;
  color: #6366f1;
  letter-spacing: 0.5px;
}

/* Date and questions count */
.company-meta {
  display: flex;
  align-items: center;
  gap: 20px;
}

.date-container {
  background: rgba(40, 40, 44, 0.8);
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
}

.questions-count {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.9);
}

/* Question list styles */
.question-list {
  margin-top: 15px;
  padding: 0 10px;
}

.question-item {
  background: rgba(28, 28, 32, 0.8);
  border-radius: 15px;
  margin-bottom: 10px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.06);
}

.question-number {
  color: #6366f1;
  font-size: 1.1rem;
  margin-right: 15px;
}

.question-title {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
  font-weight: 500;
}

/* Button styles */
.action-buttons {
  display: flex;
  gap: 10px;
}

.problem-statement-btn {
  background: rgba(16, 185, 129, 0.2);
  color: #10b981;
  border: 1px solid rgba(16, 185, 129, 0.3);
  padding: 8px 15px;
  border-radius: 20px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.get-solution-btn {
  background: rgba(99, 102, 241, 0.2);
  color: #6366f1;
  border: 1px solid rgba(99, 102, 241, 0.3);
  padding: 8px 15px;
  border-radius: 20px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

/* Available badge */
.available-badge {
  background: rgba(16, 185, 129, 0.2);
  color: #10b981;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  border: 1px solid rgba(16, 185, 129, 0.3);
}

/* Hover effects */
.company-row:hover {
  background: rgba(32, 32, 36, 0.8);
  transform: translateY(-2px);
}

.problem-statement-btn:hover,
.get-solution-btn:hover {
  transform: translateY(-1px);
  filter: brightness(1.1);
}

/* Table header */
.company-table-header {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 50px;
  padding: 15px 25px;
  background: rgba(28, 28, 32, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 16px;
  margin: 20px 0;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
}

/* Add these new styles */
.hero-section {
  width: 100%;
  padding: 40px 20px;
  text-align: center;
  background: var(--glass-bg-dark);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: var(--glass-border);
  border-radius: 25px;
  margin-bottom: 30px;
  box-shadow: var(--glass-shadow);
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.hero-title {
  font-size: 4.5rem;
  font-weight: 800;
  margin-bottom: 15px;
  letter-spacing: -1px;
  color: var(--text-primary);
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.gradient-text {
  background: linear-gradient(135deg, #bd93f9, #ff79c6);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  padding-right: 10px;
}

.hero-subtitle {
  font-size: 1.2rem;
  color: var(--text-secondary);
  font-weight: 500;
  margin: 0;
  opacity: 0.8;
  background: var(--glass-bg-light);
  padding: 10px 20px;
  border-radius: 50px;
  display: inline-block;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: var(--glass-border);
}

/* Media Queries */
@media (max-width: 768px) {
  .hero-title {
    font-size: 3rem;
  }
  
  .hero-subtitle {
    font-size: 1rem;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 2.5rem;
  }
  
  .hero-subtitle {
    font-size: 0.9rem;
    padding: 6px 12px;
  }
}

/* Main container styling */
.company-oa-wrapper {
  max-width: 1200px;
  margin: 40px auto; /* Reduced from 80px to 40px */
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
}

/* Hero section with title */
.hero-container {
  background: none;
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
  border: none;
  box-shadow: none;
  padding: 40px;
  margin-bottom: 30px;
  text-align: center;
}

.hero-title {
  font-size: 3.5rem;
  margin-bottom: 20px;
  color: #fff;
}

.hero-title .oa-text {
  color: #bd93f9;
  font-weight: 800;
}

.hero-subtitle {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.7);
  background: rgba(255, 255, 255, 0.05);
  padding: 12px 24px;
  border-radius: 50px;
  display: inline-block;
  border: 1px solid rgba(255, 255, 255, 0.08);
}

/* kam container */
.kam-container {
  background: none;
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 20px 0;
}

.kam-input {
  background: transparent;
  border: none;
  box-shadow: none;
}

.kam-input:focus {
  background: transparent;
  border: none;
  box-shadow: none;
}

/* Table container */
.table-container {
  background: none;
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
  border: none;
  box-shadow: none;
  padding: 25px;
  margin-top: 30px;
}

/* Table header */
.company-table-header {
  display: grid;
  grid-template-columns: 2fr 1fr 100px 120px 40px;
  gap: 20px;
  padding: 15px 20px;
  background: rgba(28, 28, 32, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 16px;
  margin: 20px 0;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
}

/* Spacing fix for company rows */
.company-table-body {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .company-oa-wrapper {
    margin: 30px auto;
    padding: 0 15px;
  }

  .hero-container {
    padding: 30px 20px;
  }

  .hero-title {
    font-size: 2.5rem;
  }

  .kam-container,
  .table-container {
    padding: 15px;
  }
}

/* Add these pagination styles */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
  padding: 15px;
}

.pagination-button {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pagination-button:hover:not(:disabled) {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-1px);
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-info {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
}

.current-page {
  background: rgba(189, 147, 249, 0.2);
  border-color: rgba(189, 147, 249, 0.4);
}

/* kam loading animation */
.kam-loading {
  position: absolute;
  right: 45px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  gap: 4px;
}

.kam-loading span {
  width: 4px;
  height: 4px;
  background: #bd93f9;
  border-radius: 50%;
  animation: kamLoadingBounce 0.6s infinite alternate;
}

.kam-loading span:nth-child(2) {
  animation-delay: 0.2s;
}

.kam-loading span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes kamLoadingBounce {
  from {
    transform: translateY(-2px);
    opacity: 0.3;
  }
  to {
    transform: translateY(2px);
    opacity: 1;
  }
}

/* Update kam bar styles */
.kam-bar-container {
  position: relative;
}

.kam-input {
  padding-right: 85px; /* Increased to accommodate loading animation */
}

/* Fade animation for kam results */
.kam-results-fade-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.kam-results-fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.kam-results-fade-exit {
  opacity: 1;
}

.kam-results-fade-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 300ms, transform 300ms;
}

/* Add these new styles for the hero section */
.hero-glass-container {
  position: relative;
  width: 100%;
  padding: 40px 20px; /* Reduced padding */
  margin-bottom: 40px;
  /* Removed background, backdrop-filter, and border */
  border-radius: 30px;
  overflow: hidden;
  /* Removed box-shadow */
}

.hero-content {
  position: relative;
  z-index: 2;
  text-align: center;
}

/* Update hero title styles */
.hero-title {
  font-size: 4.5rem; /* Slightly reduced */
  font-weight: 800;
  margin: 0;
  padding: 0;
  letter-spacing: -2px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

/* Update subtitle styles - remove background */
.hero-subtitle {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  padding: 12px 30px;
  /* Removed background, backdrop-filter, and border */
  display: inline-block;
}

/* Remove glass overlay */
.hero-glass-overlay {
  display: none;
}

/* Keep the glow effect but make it more subtle */
.hero-glow {
  opacity: 0.7;
  filter: blur(50px);
}

/* Update media queries */
@media (max-width: 768px) {
  .hero-glass-container {
    padding: 30px 20px;
  }

  .hero-title {
    font-size: 3.5rem;
    gap: 10px;
  }

  .hero-subtitle {
    font-size: 1rem;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

.companies-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

/* Update the kam wrapper positioning */
.kam-minimal-wrapper {
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  padding: 0 20px;
}

/* Add these global styles near the top */
* {
  -webkit-tap-highlight-color: transparent; /* Removes tap highlight on mobile */
}

button, 
input,
a {
  outline: none; /* Removes outline on all interactive elements */
}

button:focus,
input:focus,
a:focus {
  outline: none;
}

.kam-input {
  /* ... existing styles ... */
  outline: none;
}

.kam-input:focus {
  outline: none;
}

/* Add these new styles */
.sleek-solution-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: var(--sleek-text-primary);
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.sleek-solution-btn:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: translateY(-1px);
}

.sleek-solution-btn svg {
    font-size: 0.9rem;
    opacity: 0.8;
}

/* Update the problem statement spacing */
.sleek-problem-statement {
    background: var(--sleek-bg-secondary);
    border-radius: 12px;
    border: 1px solid var(--sleek-border);
    overflow: hidden;
    margin: 16px 0 24px 0;
}

.code-editor-container {
    scroll-margin-top: 20px;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .sleek-solution-btn {
        width: 100%;
        justify-content: center;
        padding: 10px 16px;
    }
}

/* Add these styles to your existing CSS */
.resume-review-btn {
    background: rgba(189, 147, 249, 0.1);
    border: 1px solid #bd93f9;
    color: #bd93f9;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.resume-review-btn:hover {
    background: rgba(189, 147, 249, 0.2);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    position: relative;
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    background: #282a36;
    border-radius: 16px;
    padding: 24px;
}

.close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.7);
    font-size: 24px;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;
}

.close-btn:hover {
    background: rgba(255, 255, 255, 0.1);
}

.question-link {
  color: #4CAF50;
  text-decoration: none;
  transition: color 0.3s ease;
}

.question-link:hover {
  color: #45a049;
}
