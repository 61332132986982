.resume-review-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 0 20px;
  }
  
  .resume-review-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .resume-review-header h1 {
    font-size: 2.5rem;
    color: rgba(255, 255, 255, 0.95);
    margin-bottom: 10px;
    font-weight: 700;
  }
  
  .resume-review-header p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.1rem;
  }
  
  .resume-review-content {
    background: rgba(28, 28, 32, 0.6);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 24px;
    padding: 40px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  }
  
  .upload-section {
    margin-bottom: 30px;
  }
  
  .upload-area {
    background: rgba(32, 32, 36, 0.6);
    border: 2px dashed rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    padding: 40px;
    text-align: center;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .upload-area.dragging {
    background: rgba(40, 40, 44, 0.8);
    border-color: rgba(255, 255, 255, 0.2);
    transform: scale(1.02);
  }
  
  .upload-icon {
    font-size: 48px;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 20px;
  }
  
  .upload-area h3 {
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 10px;
    font-size: 1.2rem;
  }
  
  .upload-area p {
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 15px;
  }
  
  .upload-button {
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.9);
    padding: 10px 24px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: inline-block;
  }
  
  .upload-button:hover {
    background: rgba(255, 255, 255, 0.15);
    transform: translateY(-2px);
  }
  
  .file-info {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: rgba(255, 255, 255, 0.9);
  }
  
  .check-icon {
    color: #50fa7b;
  }
  
  .email-input-container {
    padding: 0 24px 24px;
  }
  
  .email-input {
    width: 100%;
    background: #2a2a2a;
    border: 1px solid #333;
    border-radius: 8px;
    padding: 14px 16px;
    color: white;
    font-size: 16px;
  }
  
  .error-message {
    color: #e74c3c;
    text-align: center;
    margin-top: 10px;
  }
  
  .success-message {
    color: #50fa7b;
    text-align: center;
    margin-top: 10px;
  }
  
  .submit-button {
    width: 100%;
    background: #bd93f9;
    color: white;
    border: none;
    border-radius: 12px;
    padding: 16px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .submit-button:hover:not(:disabled) {
    background: #caa9fa;
    transform: translateY(-2px);
  }
  
  .submit-button.disabled {
    background: rgba(189, 147, 249, 0.3);
    cursor: not-allowed;
  }
  
  @media (max-width: 768px) {
    .resume-review-content {
      padding: 24px;
    }
    
    .upload-area {
      padding: 24px;
    }
  }
  
  .loading-spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  
  .analysis-results {
    margin-top: 40px;
    padding: 30px;
    background: rgba(28, 28, 32, 0.6);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    animation: fadeIn 0.5s ease-out;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  .analysis-results h2 {
    color: #bd93f9;
    margin-bottom: 24px;
    text-align: center;
  }
  
  .score-section {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  
  .score-circle {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: rgba(189, 147, 249, 0.1);
    border: 2px solid #bd93f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    animation: scaleIn 0.5s ease-out;
  }
  
  @keyframes scaleIn {
    from { transform: scale(0.8); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
  }
  
  .score-number {
    font-size: 2.5rem;
    font-weight: bold;
    color: #bd93f9;
  }
  
  .score-label {
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.7);
  }
  
  .analysis-section {
    margin-bottom: 24px;
    padding: 20px;
    background: rgba(40, 42, 54, 0.4);
    border-radius: 12px;
    animation: slideIn 0.5s ease-out;
  }
  
  @keyframes slideIn {
    from { transform: translateX(-20px); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
  }
  
  .analysis-section h3 {
    color: #ff79c6;
    margin-bottom: 12px;
  }
  
  .analysis-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .analysis-section li {
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 8px;
    padding-left: 20px;
    position: relative;
  }
  
  .analysis-section li:before {
    content: "•";
    color: #50fa7b;
    position: absolute;
    left: 0;
  }
  
  .analysis-section p {
    color: rgba(255, 255, 255, 0.9);
    line-height: 1.6;
  }