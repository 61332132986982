.sde-sheet-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.sde-sheet-header {
    text-align: center;
    margin-bottom: 40px;
}

.sde-sheet-header h1 {
    font-size: 2.5rem;
    color: var(--sleek-text-primary);
    margin-bottom: 10px;
}

.sde-sheet-header p {
    color: var(--sleek-text-secondary);
    font-size: 1.1rem;
}

.sde-search-box {
    position: relative;
    max-width: 600px;
    margin: 20px auto;
}

.sde-search-box input {
    width: 100%;
    padding: 12px 40px;
    background: var(--sleek-bg-secondary);
    border: 1px solid var(--sleek-border);
    border-radius: 8px;
    color: var(--sleek-text-primary);
    font-size: 1rem;
}

.sde-question-card {
    background: var(--sleek-bg-secondary);
    border: 1px solid var(--sleek-border);
    border-radius: 12px;
    margin-bottom: 16px;
    overflow: hidden;
}

.sde-question-header {
    display: flex;
    align-items: center;
    padding: 16px;
    gap: 16px;
}

.question-number {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--sleek-accent);
    border-radius: 50%;
    color: var(--sleek-text-primary);
    font-weight: 600;
}

.question-main-info {
    flex: 1;
}

.question-main-info h3 {
    color: var(--sleek-text-primary);
    margin-bottom: 4px;
}

.company-name {
    color: var(--sleek-text-secondary);
    font-size: 0.9rem;
}

.question-actions {
    display: flex;
    gap: 12px;
}

.original-link,
.expand-btn {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 6px 12px;
    border-radius: 6px;
    background: var(--sleek-accent);
    color: var(--sleek-text-primary);
    border: 1px solid var(--sleek-border);
    cursor: pointer;
    transition: all 0.3s ease;
}

.sde-question-content {
    padding: 16px;
    border-top: 1px solid var(--sleek-border);
}

.solution-preview {
    position: relative;
    margin-top: 20px;
    height: 200px;
    background: var(--sleek-bg-primary);
    border-radius: 8px;
    overflow: hidden;
}

.solution-blur {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(8px);
    background: rgba(0, 0, 0, 0.5);
    color: var(--sleek-text-primary);
    gap: 12px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .sde-sheet-header h1 {
        font-size: 2rem;
    }
    
    .question-actions {
        flex-direction: column;
    }
    
    .sde-question-header {
        flex-wrap: wrap;
    }
}