:root {
  --primary-color: #2563eb;
  --primary-hover: #1d4ed8;
  --secondary-color: #4f46e5;
  --secondary-hover: #4338ca;
  --text-light: #ffffff;
  --glass-bg: rgba(255, 255, 255, 0.08);
  --glass-border: rgba(255, 255, 255, 0.12);
  --navbar-height: 70px;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 72px;
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 1000;
  transition: all 0.3s ease;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.navbar.scrolled {
  background: rgba(0, 0, 0, 0.85);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-logo {
  text-decoration: none;
  display: flex;
  align-items: baseline;
  gap: 4px;
}

.logo-text {
  font-size: 1.5rem;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: -0.5px;
}

.logo-helper {
  font-size: 1.3rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 32px;
}

.nav-link {
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.95rem;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
  padding: 8px 12px;
  border-radius: 8px;
}

.nav-link:hover {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-1px);
}

.nav-link svg {
  font-size: 1.1rem;
}

.sparkle {
  font-size: 1.1rem;
  animation: sparkleAnim 1.5s infinite;
}

@keyframes sparkleAnim {
  0%, 100% { 
    transform: scale(1); 
    opacity: 1; 
  }
  50% { 
    transform: scale(0.85); 
    opacity: 0.7; 
  }
}

@media screen and (max-width: 768px) {
  .navbar-container {
    padding: 0 16px;
  }

  .nav-links {
    gap: 24px;
  }
}

@media screen and (max-width: 480px) {
  .navbar-container {
    padding: 0 12px;
  }

  .nav-links {
    gap: 12px;
  }

  .nav-link {
    padding: 8px;
  }

  .nav-link span:not(.sparkle) {
    display: none;
  }

  .nav-link svg {
    font-size: 1.2rem;
  }
}