:root {
    --sleek-bg-primary: rgba(18, 18, 18, 0.7);
    --sleek-bg-secondary: rgba(28, 28, 28, 0.6);
    --sleek-border: rgba(255, 255, 255, 0.08);
    --sleek-text-primary: rgba(255, 255, 255, 0.95);
    --sleek-text-secondary: rgba(255, 255, 255, 0.6);
    --sleek-accent: rgba(255, 255, 255, 0.05);
    --sleek-hover: rgba(255, 255, 255, 0.1);
    --sleek-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
    --sleek-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .sleek-table-header,
  .sleek-data {
    display: grid;
    grid-template-columns: minmax(200px, 2fr) 180px 80px 120px 50px;
    gap: 24px;
    padding: 16px 24px;
    align-items: center;
  }
  
  .sleek-table-body {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .sleek-row {
    background: var(--sleek-bg-secondary);
    border-radius: 12px;
    transition: var(--sleek-transition);
    overflow: hidden;
  }
  
  .sleek-row:hover {
    transform: translateY(-1px);
    background: var(--sleek-hover);
  }
  
  .sleek-data {
    display: grid;
    grid-template-columns: 2fr 180px 80px 120px 50px;
    gap: 24px;
    padding: 16px 24px;
    align-items: center;
    cursor: pointer;
  }
  
  .sleek-name {
    display: flex;
    align-items: center;
    gap: 16px;
    min-width: 0;
  }
  
  .sleek-name-text {
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--sleek-text-primary);
    letter-spacing: 0.5px;
    background: linear-gradient(90deg, 
        rgba(255, 255, 255, 0.95) 0%, 
        rgba(255, 255, 255, 0.85) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: var(--sleek-transition);
    text-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
  }
  
  .sleek-row:hover .sleek-name-text {
    background: linear-gradient(90deg, 
        rgba(255, 255, 255, 1) 0%, 
        rgba(255, 255, 255, 0.95) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: translateX(4px);
    letter-spacing: 0.7px;
  }
  
  .sleek-icon-wrapper {
    width: 36px;
    height: 36px;
    min-width: 36px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--sleek-transition);
    border: 1px solid rgba(255, 255, 255, 0.08);
  }
  
  .sleek-row:hover .sleek-icon-wrapper {
    background: rgba(255, 255, 255, 0.08);
    transform: scale(1.05);
  }
  
  .sleek-icon {
    font-size: 1.2rem;
    color: var(--sleek-text-primary);
    opacity: 0.9;
    transition: var(--sleek-transition);
  }
  
  .sleek-row:hover .sleek-icon {
    opacity: 1;
  }
  
  .sleek-date {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .sleek-date-main {
    font-size: 0.875rem;
    color: var(--sleek-text-primary);
  }
  
  .sleek-date-ago {
    font-size: 0.75rem;
    color: var(--sleek-text-secondary);
  }
  
  .sleek-count {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--sleek-text-primary);
    text-align: center;
  }
  
  .sleek-badge {
    display: inline-block;
    padding: 6px 12px;
    border-radius: 6px;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.3px;
    text-transform: uppercase;
  }
  
  .sleek-badge.available {
    background: rgba(255, 255, 255, 0.1);
    color: var(--sleek-text-primary);
  }
  
  .sleek-badge.unavailable {
    background: rgba(0, 0, 0, 0.2);
    color: var(--sleek-text-secondary);
  }
  
  .sleek-toggle {
    color: var(--sleek-text-secondary);
    display: flex;
    justify-content: center;
    transition: var(--sleek-transition);
  }
  
  .sleek-row:hover .sleek-toggle {
    color: var(--sleek-text-primary);
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .sleek-table-header,
    .sleek-data {
      grid-template-columns: minmax(180px, 2fr) 140px 70px 100px 40px;
      gap: 16px;
      padding: 14px 20px;
    }
  }
  
  @media (max-width: 768px) {
    .sleek-table-header,
    .sleek-data {
      grid-template-columns: minmax(140px, 1fr) auto !important;
      gap: 8px;
      padding: 10px 12px;
    }
    
    .hide-mobile,
    .desktop-only,
    .sleek-count,
    .sleek-solutions {
      display: none !important;
    }
    
    .sleek-name {
      gap: 6px;
      max-width: calc(100vw - 80px);
    }
    
    .sleek-icon-wrapper {
      width: 28px;
      height: 28px;
      min-width: 28px;
      border-radius: 6px;
    }
    
    .sleek-name-text {
      font-size: 0.85rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100vw - 120px);
    }
    
    .sleek-date {
      text-align: right;
      min-width: 60px;
      font-size: 0.75rem;
      color: var(--sleek-text-secondary);
    }
    
    .table-scroll-container {
      overflow-x: hidden;
    }
    
    .table-inner-container {
      min-width: unset;
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .sleek-table-header,
    .sleek-data {
      grid-template-columns: minmax(120px, 1fr) auto;
      gap: 8px;
      padding: 10px 12px;
    }
    
    .sleek-name {
      gap: 6px;
    }
    
    .sleek-icon-wrapper {
      width: 28px;
      height: 28px;
      border-radius: 8px;
    }
  }
  
  /* Question Container Styles */
  .sleek-questions-container {
    margin-top: 8px;
    padding: 16px 24px;
    background: rgba(0, 0, 0, 0.2);
    border-top: 1px solid var(--sleek-border);
    border-radius: 0 0 12px 12px;
  }
  
  .sleek-question-row {
    background: var(--sleek-bg-secondary);
    border-radius: 10px;
    margin-bottom: 8px;
    transition: var(--sleek-transition);
    overflow: hidden;
  }
  
  .sleek-question-row:hover {
    transform: translateY(-1px);
    background: rgba(40, 40, 45, 0.6);
  }
  
  .sleek-question-content {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }
  
  .sleek-question-info {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1;
  }
  
  .sleek-question-number {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--sleek-accent);
    border-radius: 8px;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--sleek-text-secondary);
  }
  
  .sleek-question-title {
    margin: 0;
    font-size: 0.9375rem;
    font-weight: 500;
    color: var(--sleek-text-primary);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .sleek-expand-icon {
    color: var(--sleek-text-secondary);
    transition: var(--sleek-transition);
  }
  
  .sleek-question-row:hover .sleek-expand-icon {
    color: var(--sleek-text-primary);
  }
  
  .sleek-question-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 8px;
  }
  
  .sleek-solution-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: var(--sleek-text-primary);
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .sleek-solution-btn:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: translateY(-1px);
  }
  
  .sleek-solution-btn svg {
    font-size: 0.9rem;
    opacity: 0.8;
  }
  
  .sleek-original-btn,
  .sleek-solution-btn {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 12px;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8px);
    text-decoration: none;
  }
  
  /* Original Problem button - Gold theme */
  .sleek-original-btn {
    background: rgba(218, 165, 32, 0.15);
    color: #FFD700;
    box-shadow: 0 2px 8px rgba(218, 165, 32, 0.15);
  }
  
  .sleek-original-btn:hover {
    background: rgba(218, 165, 32, 0.2);
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(218, 165, 32, 0.2);
  }
  
  /* Get Solution button - Emerald theme */
  .sleek-solution-btn {
    background: rgba(0, 158, 96, 0.15);
    color: #00E676;
    box-shadow: 0 2px 8px rgba(0, 158, 96, 0.15);
  }
  
  .sleek-solution-btn:hover {
    background: rgba(0, 158, 96, 0.2);
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 158, 96, 0.2);
  }
  
  /* Available/Unavailable badges */
  .sleek-badge {
    display: inline-flex;
    align-items: center;
    padding: 6px 14px;
    border-radius: 10px;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    backdrop-filter: blur(8px);
    transition: all 0.2s ease;
  }
  
  .sleek-badge.available {
    background: rgba(0, 158, 96, 0.15);
    color: #00E676;
    border: 1px solid rgba(0, 200, 83, 0.2);
    box-shadow: 0 2px 6px rgba(0, 158, 96, 0.1);
  }
  
  .sleek-badge.unavailable {
    background: rgba(158, 0, 0, 0.15);
    color: #FF5252;
    border: 1px solid rgba(200, 0, 0, 0.2);
    box-shadow: 0 2px 6px rgba(158, 0, 0, 0.1);
  }
  
  /* Subtle icon transition */
  .sleek-original-btn svg,
  .sleek-solution-btn svg {
    transition: transform 0.2s ease;
  }
  
  .sleek-original-btn:hover svg,
  .sleek-solution-btn:hover svg {
    transform: translateX(2px);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .sleek-original-btn,
    .sleek-solution-btn {
        padding: 6px 12px;
        font-size: 0.8125rem;
    }

    .sleek-badge {
        padding: 4px 10px;
        font-size: 0.7rem;
    }
  }
  
  .sleek-problem-statement {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
    background: var(--sleek-bg-primary);
    border-top: 1px solid var(--sleek-border);
    border-radius: 8px;
    margin-top: 8px;
  }
  
  .sleek-original-btn {
    background: rgba(218, 165, 32, 0.15);
    color: #FFD700;
    box-shadow: 0 2px 8px rgba(218, 165, 32, 0.15);
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 0.875rem;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    gap: 8px;
  }
  
  .sleek-original-btn:hover {
    background: rgba(218, 165, 32, 0.2);
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(218, 165, 32, 0.2);
  }
  
  .sleek-no-questions {
    text-align: center;
    color: var(--sleek-text-secondary);
    padding: 24px;
    font-size: 0.9375rem;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .sleek-questions-container {
      padding: 12px;
    }
  }
  
  /* Payment Popup Styles */
  .sleek-popup-overlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    backdrop-filter: blur(8px);
    padding: 20px;
  }
  
  .sleek-popup-content {
    background: #1a1a1a;
    border-radius: 24px;
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .pricing-cards-container {
    display: flex;
    gap: 16px;
    padding: 24px;
  }
  
  .pricing-card {
    flex: 1;
    background: #2a2a2a;
    border-radius: 20px;
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    min-height: 520px;
  }
  
  .pricing-card.premium {
    background: #1e1b2d;
  }
  
  .card-header {
    text-align: center;
    margin-bottom: 24px;
  }
  
  .card-icon {
    font-size: 28px;
    color: #6366f1;
    margin-bottom: 16px;
  }
  
  .card-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  
  .price-block {
    text-align: center;
    margin: 32px 0;
  }
  
  .price {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 4px;
  }
  
  .rupee {
    font-size: 24px;
  }
  
  .amount {
    font-size: 48px;
    font-weight: 700;
  }
  
  .period {
    color: #666;
    font-size: 16px;
    margin-top: 4px;
  }
  
  .features-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 32px;
  }
  
  .feature-item {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #999;
    font-size: 16px;
  }
  
  .feature-item svg {
    color: #10b981;
    font-size: 18px;
  }
  
  .best-value-badge {
    background: #6366f1;
    color: white;
    font-size: 14px;
    font-weight: 600;
    padding: 6px 16px;
    border-radius: 20px;
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }
  
  .buy-button {
    background: #2f2f2f;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 14px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 100%;
  }
  
  .buy-button.premium {
    background: #6366f1;
  }
  
  .email-input-container {
    padding: 0 24px 24px;
  }
  
  .email-input {
    width: 100%;
    background: #2a2a2a;
    border: 1px solid #333;
    border-radius: 8px;
    padding: 14px 16px;
    color: white;
    font-size: 16px;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .sleek-popup-overlay {
        padding: 16px;
        align-items: flex-start;
        overflow-y: auto;
    }

    .sleek-popup-content {
        margin: auto 0;
    }

    .pricing-cards-container {
        flex-direction: column;
        padding: 20px;
        gap: 24px;
    }

    .pricing-card {
        min-height: unset;
        padding: 24px 20px;
    }

    .card-header {
        margin-bottom: 20px;
    }

    .price-block {
        margin: 24px 0;
    }

    .amount {
        font-size: 40px;
    }

    .features-list {
        margin-bottom: 24px;
    }

    .email-input-container {
        padding: 0 20px 20px;
    }
  }
  
  /* Base table styles */
  .table-scroll-container {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    padding: 0 8px;
  }
  
  .table-inner-container {
    width: 100%;
    min-width: unset;
  }
  
  /* Responsive grid adjustments */
  @media (max-width: 1024px) {
    .sleek-table-header,
    .sleek-data {
      grid-template-columns: 2fr 160px 70px 110px 40px;
      gap: 16px;
      padding: 14px 20px;
    }
    
    .sleek-name-text {
      font-size: 0.9375rem;
    }
  }
  
  @media (max-width: 768px) {
    .sleek-table-header,
    .sleek-data {
      grid-template-columns: 1fr 80px !important;
      gap: 8px;
      padding: 10px;
      width: 100%;
      margin: 0;
    }
    
    .desktop-only {
      display: none;
    }
    
    .sleek-icon-wrapper {
      width: 28px;
      height: 28px;
      border-radius: 10px;
    }
    
    .sleek-name-text {
      font-size: 0.9375rem;
    }
    
    .sleek-date {
      flex-direction: column;
      gap: 4px;
    }
    
    .sleek-date-ago {
      font-size: 0.6875rem;
    }
    
    .sleek-badge {
      padding: 4px 8px;
      font-size: 0.6875rem;
    }
    
    /* Question container adjustments */
    .sleek-questions-container {
      padding: 12px;
      margin: 8px 0;
    }
    
    .sleek-question-content {
      flex-direction: column;
      gap: 12px;
    }
    
    .sleek-question-info {
      width: 100%;
    }
    
    .sleek-question-actions {
      width: 100%;
      flex-direction: column;
      gap: 8px;
    }
    
    .sleek-original-btn,
    .sleek-solution-btn {
      width: 100%;
      justify-content: center;
    }
    
    .sleek-question-title {
      font-size: 0.875rem;
    }
  }
  
  @media (max-width: 480px) {
    .sleek-table-header,
    .sleek-data {
      grid-template-columns: minmax(120px, 1fr) 70px auto;
      gap: 8px;
      padding: 8px;
    }
    
    .sleek-date {
      display: none;
    }
    
    .sleek-name {
      gap: 8px;
      width: 100%;
      max-width: calc(100vw - 120px);
    }
    
    .sleek-name-text {
      font-size: 0.875rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
    
    .sleek-count {
      font-size: 0.75rem;
      min-width: 24px;
      text-align: center;
    }
    
    .sleek-badge {
      padding: 4px 6px;
      font-size: 0.6875rem;
      white-space: nowrap;
    }
    
    .sleek-question-number {
      width: 24px;
      height: 24px;
      font-size: 0.75rem;
    }
    
    .sleek-problem-statement {
      font-size: 0.8125rem;
      padding: 12px;
    }
  }
  
  /* Touch device optimizations */
  @media (hover: none) {
    .sleek-row:hover {
      transform: none;
    }
    
    .sleek-question-row:hover {
      transform: none;
    }
    
    .sleek-original-btn:active,
    .sleek-solution-btn:active {
      background: var(--sleek-hover);
    }
  }
  
  /* Prevent text wrapping in critical columns */
  .sleek-name-text,
  .sleek-badge,
  .sleek-count {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* Maintain alignment of icons and text */
  .sleek-name {
    display: flex;
    align-items: center;
    gap: 12px;
    min-width: 0;
  }
  
  /* Center align certain columns */
  .sleek-count,
  .sleek-solutions {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Update column headers styling */
  .sleek-table-header {
    color: var(--sleek-text-primary);
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-size: 0.75rem;
    border-bottom: 1px solid var(--sleek-border);
    background: var(--sleek-bg-primary);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    position: sticky;
    top: 0;
    z-index: 10;
  }
  
  /* Enhance company name styling */
  .sleek-name {
    display: flex;
    align-items: center;
    gap: 16px;
    min-width: 0;
  }
  
  .sleek-name-text {
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--sleek-text-primary);
    letter-spacing: 0.5px;
    background: linear-gradient(90deg, 
        rgba(255, 255, 255, 0.95) 0%, 
        rgba(255, 255, 255, 0.85) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: var(--sleek-transition);
    text-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
  }
  
  .sleek-row:hover .sleek-name-text {
    background: linear-gradient(90deg, 
        rgba(255, 255, 255, 1) 0%, 
        rgba(255, 255, 255, 0.95) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: translateX(4px);
    letter-spacing: 0.7px;
  }
  
  .sleek-icon-wrapper {
    width: 36px;
    height: 36px;
    min-width: 36px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--sleek-transition);
    border: 1px solid rgba(255, 255, 255, 0.08);
  }
  
  .sleek-row:hover .sleek-icon-wrapper {
    background: rgba(255, 255, 255, 0.08);
    transform: scale(1.05);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .sleek-name-text {
      font-size: 0.9375rem;
    }
    
    .sleek-icon-wrapper {
      width: 36px;
      height: 36px;
      border-radius: 10px;
    }
    
    .sleek-table-header {
      font-size: 0.6875rem;
    }
  }
  
  @media (max-width: 480px) {
    .sleek-name-text {
      font-size: 0.875rem;
    }
    
    .sleek-icon-wrapper {
      width: 32px;
      height: 32px;
      border-radius: 8px;
    }
  }
  
  /* Updated Payment Popup Styles */
  .sleek-payment-info {
    display: flex;
    gap: 20px;
    align-items: center;
    background: var(--sleek-bg-primary);
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 20px;
  }
  
  .sleek-info-icon {
    width: 48px;
    height: 48px;
    background: var(--sleek-accent);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--sleek-text-primary);
  }
  
  .sleek-payment-details h4 {
    margin: 0 0 8px 0;
    color: var(--sleek-text-primary);
    font-size: 1.1rem;
  }
  
  .sleek-payment-details p {
    margin: 0;
    color: var(--sleek-text-secondary);
    font-size: 0.9rem;
  }
  
  /* Success Popup Styles */
  .success-overlay {
    animation: fadeIn 0.3s ease-out;
  }
  
  .success-content {
    text-align: center;
    padding: 32px 24px;
  }
  
  .success-icon {
    margin-bottom: 16px;
    color: var(--sleek-text-primary);
    font-size: 32px;
  }
  
  .success-content h3 {
    margin: 0 0 8px 0;
    color: var(--sleek-text-primary);
  }
  
  .success-content p {
    margin: 0;
    color: var(--sleek-text-secondary);
    font-size: 0.9rem;
  }
  
  .sleek-done-btn {
    background: var(--sleek-accent);
    color: var(--sleek-text-primary);
    border: none;
    padding: 12px 32px;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: var(--sleek-transition);
    animation: fadeInUp 0.3s ease-out 0.3s both;
  }
  
  .sleek-done-btn:hover {
    background: var(--sleek-hover);
    transform: translateY(-2px);
  }
  
  @keyframes scaleIn {
    from {
        transform: scale(0.8);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
  }
  
  @keyframes fadeInUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
  }
  
  /* Loading Animation Styles */
  .sleek-loading-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(18, 18, 18, 0.95);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    z-index: 1000;
  }
  
  .sleek-loading-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  
  .sleek-loading-spinner {
    width: 64px;
    height: 64px;
    position: relative;
    animation: spinnerRotate 2s linear infinite;
  }
  
  .sleek-loading-spinner::before,
  .sleek-loading-spinner::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: rgba(255, 255, 255, 0.95);
  }
  
  .sleek-loading-spinner::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: spinnerBeforeRotate 1.5s cubic-bezier(0.55, 0.15, 0.45, 0.85) infinite;
  }
  
  .sleek-loading-spinner::after {
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
    border-top-color: rgba(255, 255, 255, 0.6);
    animation: spinnerAfterRotate 1s cubic-bezier(0.55, 0.15, 0.45, 0.85) infinite;
  }
  
  .sleek-loading-text {
    font-size: 0.95rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.9);
    letter-spacing: 0.5px;
    animation: textPulse 2s ease-in-out infinite;
  }
  
  .sleek-loading-dots {
    display: flex;
    gap: 4px;
  }
  
  .sleek-loading-dot {
    width: 4px;
    height: 4px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
  }
  
  .sleek-loading-dot:nth-child(1) { animation: dotPulse 1.4s ease-in-out infinite; }
  .sleek-loading-dot:nth-child(2) { animation: dotPulse 1.4s ease-in-out 0.2s infinite; }
  .sleek-loading-dot:nth-child(3) { animation: dotPulse 1.4s ease-in-out 0.4s infinite; }
  
  @keyframes spinnerRotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  
  @keyframes spinnerBeforeRotate {
    0% { transform: rotate(0deg) scale(1); }
    50% { transform: rotate(180deg) scale(0.95); }
    100% { transform: rotate(360deg) scale(1); }
  }
  
  @keyframes spinnerAfterRotate {
    0% { transform: rotate(0deg) scale(0.95); }
    50% { transform: rotate(-180deg) scale(1); }
    100% { transform: rotate(-360deg) scale(0.95); }
  }
  
  @keyframes textPulse {
    0%, 100% { opacity: 0.7; }
    50% { opacity: 1; }
  }
  
  @keyframes dotPulse {
    0%, 100% { transform: translateY(0); opacity: 0.5; }
    50% { transform: translateY(-4px); opacity: 1; }
  }
  
  .sleek-payment-note {
    padding: 12px;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.05);
  }
  
  .sleek-payment-note p {
    margin: 0 0 8px 0;
    color: var(--sleek-text-primary);
    font-size: 0.9rem;
    font-weight: 500;
  }
  
  .sleek-payment-note ul {
    margin: 0;
    padding-left: 20px;
    color: var(--sleek-text-secondary);
    font-size: 0.85rem;
  }
  
  .sleek-payment-note li {
    margin-bottom: 4px;
  }
  
  .sleek-payment-note li:last-child {
    margin-bottom: 0;
  }
  
  .sleek-share-btn {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: var(--sleek-transition);
    border: 1px solid var(--sleek-border);
    background: var(--sleek-bg-primary);
    color: var(--sleek-text-primary);
  }
  
  .sleek-share-btn:hover {
    background: var(--sleek-hover);
    transform: translateY(-1px);
  }
  
  .sleek-share-btn.shared {
    background: rgba(39, 174, 96, 0.2);
    border-color: rgba(39, 174, 96, 0.3);
    color: rgba(39, 174, 96, 0.9);
  }
  
  @keyframes highlight {
    0% { 
        background: var(--sleek-bg-secondary);
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
    }
    50% { 
        background: rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
    }
    100% { 
        background: var(--sleek-bg-secondary);
        box-shadow: 0 0 0 2px transparent;
    }
  }
  
  .question-highlight {
    animation: highlight 2s ease-out;
  }
  
  /* Update question row styles */
  .sleek-question-row {
    scroll-margin-top: 100px;
    transition: background 0.3s ease;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .sleek-question-actions {
        flex-direction: row;
        flex-wrap: wrap;
    }
    
    .sleek-share-btn {
        padding: 6px 12px;
        font-size: 0.8125rem;
    }
  }
  
  /* Mobile styles */
  @media (max-width: 768px) {
    .sleek-table-header {
        display: none !important;  /* Hide the header on mobile */
    }

    .table-scroll-container {
        padding: 0 16px;
    }

    .sleek-table-body {
        margin-left: 8px;
    }

    .sleek-data {
        display: flex !important;
        padding: 12px 8px;
        width: calc(100% - 16px);
        margin: 0 0 0 8px;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        position: relative;
        background: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.06);
    }

    .sleek-name {
        display: flex;
        align-items: center;
        gap: 8px;
        max-width: 180px;
        min-width: 140px;
    }

    .sleek-icon-wrapper {
        width: 24px;
        height: 24px;
        min-width: 24px;
        border-radius: 6px;
    }

    .sleek-name-text {
        font-size: 0.875rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 32px);
    }
}

/* Extra small devices */
@media (max-width: 480px) {
    .table-scroll-container {
        padding: 0 12px;
    }

    .sleek-table-body {
        margin-left: 6px;
    }

    .sleek-data {
        padding: 14px 8px;
        width: calc(100% - 12px);
        margin: 0 0 0 6px;
    }

    .sleek-name {
        max-width: 140px;
        min-width: 120px;
    }

    .sleek-icon-wrapper {
        width: 20px;
        height: 20px;
        min-width: 20px;
    }

    .sleek-name-text {
        font-size: 0.8125rem;
    }
}

/* Add at the top of the file */
.scroll-progress-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 2000;
    backdrop-filter: blur(8px);
}

.scroll-progress-bar {
    height: 100%;
    background: linear-gradient(90deg, 
        rgba(255, 255, 255, 0.7) 0%,
        rgba(255, 255, 255, 0.95) 50%,
        rgba(255, 255, 255, 0.7) 100%
    );
    transition: width 0.1s ease-out;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
    border-radius: 0 3px 3px 0;
}

/* Optional: Add a subtle glow animation */
@keyframes progressGlow {
    0% { box-shadow: 0 0 10px rgba(255, 255, 255, 0.3); }
    50% { box-shadow: 0 0 15px rgba(255, 255, 255, 0.4); }
    100% { box-shadow: 0 0 10px rgba(255, 255, 255, 0.3); }
}

.scroll-progress-bar {
    animation: progressGlow 2s ease-in-out infinite;
}

/* Add these new styles */
.code-editor-container {
    margin-top: 20px;
    background: rgba(30, 30, 30, 0.95);
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.code-editor-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    background: rgba(40, 40, 40, 0.95);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.code-editor-tabs {
    display: flex;
    gap: 16px;
}

.code-tab {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 12px;
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.05);
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.code-tab.active {
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.95);
}

.code-tab-icon {
    flex-shrink: 0;
    font-size: 14px;
    color: #00599C;
}

.code-unlock-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 12px;
    font-size: 0.875rem;
    color: #00E676;
    background: rgba(0, 158, 96, 0.15);
    border: 1px solid rgba(0, 200, 83, 0.2);
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.code-unlock-btn:hover {
    background: rgba(0, 158, 96, 0.2);
    transform: translateY(-1px);
}

.code-editor-content {
    position: relative;
    min-height: 200px;
}

.code-content {
    margin: 0;
    padding: 16px;
    font-family: 'Consolas', 'Monaco', monospace;
    font-size: 0.875rem;
    line-height: 1.5;
    color: rgba(255, 255, 255, 0.9);
    white-space: pre;
    overflow-x: auto;
}

.code-content.blurred {
    filter: blur(2px);
    user-select: none;
    pointer-events: none;
}

.code-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(2px);
}

.overlay-lock-icon {
    font-size: 24px;
    color: rgba(255, 255, 255, 0.9);
}

.code-overlay span {
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.9);
    font-weight: 500;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .code-editor-container {
        margin: 16px -16px 0;
        border-radius: 0;
    }
    
    .code-content {
        font-size: 0.8125rem;
        padding: 12px;
    }
    
    .code-tab {
        font-size: 0.8125rem;
        padding: 4px 8px;
    }
}

/* Enhanced footer styles with detailed legal notice */
.sleek-footer {
    margin-top: 100px;
    padding: 60px 24px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.footer-links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.footer-link {
    display: flex;
    align-items: center;
    gap: 16px;
    color: var(--sleek-text-secondary);
    text-decoration: none;
    font-size: 1.4rem;
    font-weight: 300;
    letter-spacing: 0.4px;
    transition: color 0.3s ease;
}

.footer-link:hover {
    color: var(--sleek-text-primary);
}

.footer-link svg {
    font-size: 1.5rem;
}

.footer-divider {
    color: var(--sleek-text-secondary);
    opacity: 0.3;
    font-size: 1.4rem;
    font-weight: 200;
}

.footer-legal {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 800px;
    margin: 0 auto;
}

.footer-notice {
    color: var(--sleek-text-secondary);
    font-size: 1.3rem;
    letter-spacing: 0.5px;
    font-weight: 300;
    line-height: 1.6;
}

.footer-warning {
    color: var(--sleek-text-secondary);
    font-size: 1.2rem;
    opacity: 0.8;
    letter-spacing: 0.4px;
    font-weight: 300;
    line-height: 1.6;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .footer-links {
        flex-direction: column;
        gap: 32px;
    }
    
    .footer-divider {
        display: none;
    }
    
    .sleek-footer {
        padding: 40px 20px;
    }
    
    .footer-link {
        font-size: 1.3rem;
    }
    
    .footer-notice {
        font-size: 1.1rem;
    }
    
    .footer-warning {
        font-size: 1rem;
    }
}

/* Add scroll margin to code section */
.code-editor-container {
    scroll-margin-top: 20px;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .sleek-question-actions {
        flex-direction: column;
        gap: 8px;
    }
    
    .sleek-solution-btn,
    .sleek-original-btn {
        width: 100%;
        justify-content: center;
    }
}

/* Update the pricing cards layout */
.pricing-options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 30px;
}

.pricing-card {
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    padding: 24px;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.pricing-card.premium {
    background: linear-gradient(135deg, rgba(99, 102, 241, 0.1), rgba(168, 85, 247, 0.1));
    border: 1px solid rgba(99, 102, 241, 0.2);
}

/* Mobile responsiveness improvements */
@media (max-width: 768px) {
    .pricing-options {
        grid-template-columns: 1fr;
        gap: 16px;
    }

    .pricing-card {
        padding: 20px;
    }

    .payment-buttons {
        grid-template-columns: 1fr;
        gap: 12px;
    }

    .sleek-popup-content {
        width: 90%;
        max-width: 400px;
        margin: 20px;
        padding: 20px;
    }

    .pricing-title {
        font-size: 1.5rem;
        margin-bottom: 20px;
    }

    .sleek-email-input {
        margin-top: 16px;
    }
}

/* Enhance pricing card content layout */
.pricing-header {
    text-align: center;
    margin-bottom: 20px;
}

.pricing-features {
    flex-grow: 1;
    margin-bottom: 20px;
}

.pricing-features ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.pricing-features li {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
    color: var(--text-secondary);
}

.price {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    margin: 16px 0;
}

.amount {
    font-size: 2rem;
    font-weight: 600;
    color: var(--text-primary);
}

.period {
    font-size: 0.9rem;
    color: var(--text-secondary);
}

/* Update question title styling for glass aesthetic */
.sleek-question-title {
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
}

.sleek-question-title .question-link {
    color: #e2e8f0;
    text-decoration: none;
    padding: 8px 12px;
    border-radius: 6px;
    display: inline-block;
    transition: all 0.2s ease;
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.08);
}

.sleek-question-title .question-link:hover {
    background: rgba(255, 255, 255, 0.08);
    transform: translateY(-1px);
    color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Question number styling */
.sleek-question-number {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    font-size: 0.875rem;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.7);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Question info container */
.sleek-question-info {
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .sleek-question-title {
        font-size: 0.875rem;
    }

    .sleek-question-title .question-link {
        padding: 6px 10px;
    }

    .sleek-question-number {
        width: 28px;
        height: 28px;
        font-size: 0.75rem;
    }
}

/* Question link styling */
.sleek-question-link {
    display: flex;
    align-items: center;
    gap: 12px;
    text-decoration: none;
    padding: 8px 0;
    transition: all 0.2s ease;
}

.question-text {
    font-size: 1.1rem;
    font-weight: 900;
    letter-spacing: -0.2px;
    color: #5f8ea4;
    transition: all 0.2s ease;
}



.link-icon {
    font-size: 0.9rem;
    color: rgba(125, 211, 252, 0.7);
    transition: all 0.2s ease;
}

.sleek-question-link:hover .link-icon {
    color: rgba(56, 189, 248, 0.9);
    transform: scale(1.1);
}

/* Question number styling */
.sleek-question-number {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 400;
}

/* Question info container */
.sleek-question-info {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .question-text {
        font-size: 1rem;
    }

    .link-icon {
        font-size: 0.8rem;
    }

    .sleek-question-number {
        width: 24px;
        height: 24px;
        font-size: 0.85rem;
    }
}

/* Focus state for keyboard navigation */
.sleek-question-link:focus {
    outline: none;
}

.sleek-question-link:focus .question-text {
    color: #ffffff;
}