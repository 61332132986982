.search-hero {
  width: 100%;
  max-width: 720px;
  position: relative;
  margin: 120px auto 100px;
  padding: 0 20px;
  z-index: 1000;
}

.search-logo {
  
  margin-bottom: 0px;
  font-size: 3.1rem;
  font-weight: 500;
  letter-spacing: -1px;
  color: rgba(255, 255, 255, 0.95);
  text-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
}

.search-logo span {
  font-size: 2.6rem;
  font-weight:200;
  color: rgba(255, 255, 255, 0.7);
}

.search-box {
  position: relative;
  width: 100%;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 8px 24px;
  height: 40px;
  border-radius: 28px;
  background: rgba(28, 28, 32, 0.95);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.08);
}

.search-box:hover {
  background: rgba(32, 32, 38, 0.97);
  border-color: rgba(255, 255, 255, 0.12);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15),
              0 2px 8px rgba(255, 255, 255, 0.03);
  transform: translateY(-1px);
}


.search-icon {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.2rem;
  transition: all 0.3s ease;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  padding: 6px;
}







.search-input::placeholder {
  color: rgba(255, 255, 255, 0.4);
  transition: all 0.3s ease;
  font-weight: 400;
}



.search-loading {
  display: flex;
  gap: 4px;
  align-items: center;
  padding-right: 4px;
}

.search-loading span {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);
  animation: searchLoadingPulse 1.4s ease-in-out infinite;
}

.search-clear {
  color: rgba(255, 255, 255, 0.4);
  padding: 8px;
  transition: all 0.3s ease;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
  border: none;
  cursor: pointer;
  width: 32px;
  height: 32px;
}

.search-clear:hover {
  color: rgba(255, 255, 255, 0.9);
  
  transform: scale(1.05);
}

@keyframes searchLoadingPulse {
  0%, 100% { transform: translateY(0); opacity: 0.5; }
  50% { transform: translateY(-4px); opacity: 1; }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .search-hero {
    margin: 120px auto 80px;
    max-width: 600px;
  }
  
  .search-logo {
    font-size: 2.6rem;
    margin-bottom: 32px;
  }
}

@media (max-width: 480px) {
  .search-hero {
    margin: 100px auto 60px;
    max-width: 100%;
  }
  
  .search-box {
    height: 44px;
    padding: 6px 16px;
  }
  
  .search-logo {
    font-size: 2.2rem;
    margin-bottom: 28px;
  }
} 
/* Add these new styles */


.search-toggle {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  margin-right: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.search-toggle-btn {
  padding: 4px 12px;
  border-radius: 12px;
  border: none;
  background: none;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  font-size: 0.85rem;
  transition: all 0.2s ease;
}

.search-toggle-btn.active {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.95);
}

/* Update existing styles */
.search-input {
  flex: 1;
  background: transparent;
  border: none;
  padding: 0;
  color: rgba(255, 255, 255, 0.95);
  letter-spacing: 0.3px;
  font-weight: 400;
  outline: none;
  transition: all 0.3s ease;
  font-size: 0.95rem;  /* Default size */
}

/* Add animation for typing state */
.search-input:focus,
.search-input:not(:placeholder-shown) {
  font-size: 1.1rem;  /* Larger size when typing/focused */
  font-weight: 800;
  letter-spacing: 0.2px;
}

.search-input::placeholder {
  color: rgba(255, 255, 255, 0.4);
  transition: all 0.3s ease;
  font-weight: 400;
  font-size: 1.1rem;
  display: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .search-input {
    font-size: 0.9rem;
  }
  
  .search-input:focus,
  .search-input:not(:placeholder-shown) {
    font-size: 1rem;
  }
  
  .search-input::placeholder {
    font-size: 0.9rem;
  }
}

.search-type-selector {
  position: absolute;
  top: calc(100% + 16px);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
  background: rgba(28, 28, 32, 0.95);
  padding: 6px;
  border-radius: 24px;
  backdrop-filter: blur(10px);
 
}

.search-type-btn {
  padding: 8px 20px;
  border: none;
  background: none;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
  border-radius: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  letter-spacing: 0.3px;
  font-weight: 500;
}

.search-type-btn:hover {
  color: rgba(255, 255, 255, 0.9);
  background: rgba(255, 255, 255, 0.08);
}

.search-type-btn.active {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

/* Add subtle animation for type change */
@keyframes typeChange {
  0% { transform: translateY(-4px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}

.search-input.type-changed {
  animation: typeChange 0.3s ease-out;
}

.search-header {
    text-align: center;
    margin-bottom: 28px;
    margin-top: 160px;

    animation: fadeInDown 0.8s ease-out;
    font-family: -apple-system, BlinkMacSystemFont, "SF Pro Display", "SF Pro Text", "Helvetica Neue", sans-serif;
}

.search-tagline {
    margin: 0;
    font-weight: 400;
    font-size: 2.4rem;
    color: rgba(255, 255, 255, 0.75);
    letter-spacing: -0.3px;
    line-height: 1.4;
}

.tagline-highlight {
    display: block;
    font-weight: 600;
    font-size: 3.2rem;
    background: linear-gradient(135deg, 
        rgba(255, 255, 255, 0.98) 0%, 
        rgba(255, 255, 255, 0.85) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 12px 0;
    letter-spacing: -1.2px;
    text-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
    font-feature-settings: "kern" 1;
    -webkit-font-smoothing: antialiased;
}

.tagline-sub {
    font-size: 1.6rem;
    color: rgba(255, 255, 255, 0.65);
    margin-top: 12px;
    font-weight: 400;
    letter-spacing: -0.2px;
    font-feature-settings: "kern" 1;
    -webkit-font-smoothing: antialiased;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .search-tagline {
        font-size: 1.3rem;
        padding: 0 20px;
    }
    
    .tagline-highlight {
        font-size: 2.6rem;
        letter-spacing: -0.8px;
    }

    .tagline-sub {
        font-size: 1.1rem;
        letter-spacing: -0.1px;
    }
}

@media (max-width: 480px) {
    .search-tagline {
        font-size: 1.1rem;
        padding: 0 16px;
    }
    
    .tagline-highlight {
        font-size: 2rem;
        letter-spacing: -0.5px;
    }
    
    .tagline-sub {
        font-size: 0.95rem;
        letter-spacing: 0;
    }
    
    .search-header {
        margin-bottom: 36px;
    }
}

.search-input:focus {
    outline: none;
}


/* Add keyframes for the search icon animation */
@keyframes searchIconPulse {
    0% {
        transform: scale(1) rotate(0deg);
    }
    50% {
        transform: scale(1.1) rotate(-10deg);
    }
    100% {
        transform: scale(1) rotate(0deg);
    }
}

@keyframes searchIconGlow {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
    }
    50% {
        box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.15);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
    }
}

.search-icon {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.2rem;
    transition: all 0.3s ease;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 50%;
    padding: 6px;
}

/* Add the typing animation class */
.search-icon.typing {
    color: rgba(255, 255, 255, 0.9);
    background: rgba(255, 255, 255, 0.12);
    animation: 
        searchIconPulse 0.5s ease-in-out,
        searchIconGlow 1.5s ease-in-out infinite;
}

.search-box:hover .search-icon {
    color: rgba(255, 255, 255, 0.7);
    background: rgba(255, 255, 255, 0.08);
    transform: scale(1.05);
}

.search-box:focus-within .search-icon {
    color: rgba(255, 255, 255, 0.9);
    background: rgba(255, 255, 255, 0.1);
    transform: scale(1.1);
}