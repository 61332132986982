.resume-list-container {
    padding: 20px;
    background: rgba(40, 42, 54, 0.6);
    border-radius: 12px;
    margin: 20px 0;
}

.resume-list-container h2 {
    color: #bd93f9;
    margin-bottom: 20px;
}

.resume-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.resume-card {
    background: rgba(68, 71, 90, 0.4);
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    transition: transform 0.2s;
    border: 1px solid rgba(189, 147, 249, 0.1);
}

.resume-card:hover {
    transform: translateY(-2px);
    border-color: rgba(189, 147, 249, 0.3);
}

.resume-icon {
    font-size: 2rem;
    color: #ff79c6;
    text-align: center;
}

.resume-details {
    text-align: center;
}

.resume-email {
    color: #f8f8f2;
    font-weight: 500;
    margin-bottom: 4px;
}

.resume-date {
    color: #6272a4;
    font-size: 0.9rem;
}

.resume-actions {
    display: flex;
    gap: 8px;
    justify-content: center;
    margin-top: 8px;
}

.action-button {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: all 0.2s;
}

.action-button.view {
    background: rgba(189, 147, 249, 0.1);
    color: #bd93f9;
}

.action-button.download {
    background: rgba(80, 250, 123, 0.1);
    color: #50fa7b;
}

.action-button:hover {
    filter: brightness(1.2);
}

.resume-loading {
    text-align: center;
    padding: 20px;
    color: #6272a4;
}

.resume-error {
    text-align: center;
    padding: 20px;
    color: #ff5555;
}