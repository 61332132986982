.leetcode-container {
    min-height: 100vh;
    background: linear-gradient(135deg, #1a1a1a 0%, #0a0a0a 100%);
    padding: 80px 20px 20px 20px;
    position: fixed;
    inset: 0;
    overflow: hidden;
}

.leetcode-header {
    height: 60px;
    background: rgba(40, 40, 40, 0.95);
    backdrop-filter: blur(10px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
}

.header-left {
    display: flex;
    align-items: center;
    gap: 20px;
}

.header-right {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-left: auto;
}

.share-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    color: #e6e6e6;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    backdrop-filter: blur(5px);
    outline: none;
}

.share-button:hover {
    background: rgba(255, 255, 255, 0.15);
    transform: translateY(-1px);
}

.share-button:active {
    transform: translateY(0);
}

.share-button svg {
    font-size: 14px;
}

.back-button {
    color: #b3b3b3;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
}

.back-button:hover {
    color: #ffffff;
}

.header-left h1 {
    font-size: 16px;
    color: #fff;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;
}

.leetcode-content {
    display: grid;
    grid-template-columns: minmax(600px, 40%) 1fr;
    height: calc(100vh - 100px);
    gap: 20px;
    max-width: 1800px;
    margin: 0 auto;
    padding: 0 20px;
}

.description-panel {
    background: rgba(20, 20, 20, 0.95);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    overflow-y: auto;
    padding: 30px;
    height: calc(100vh - 100px);
    border-radius: 12px;
    position: relative;
}

.description-header {
    padding: 20px 0;
    margin-bottom: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.title-section {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 20px;
}

.company-header {
    margin: 40px 0 32px 0;
    display: flex;
    align-items: center;
}

.company-badge {
    display: inline-flex;
    align-items: center;
    padding: 16px 32px;
    background: rgba(30, 30, 30, 0.95);
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 16px;
    font-size: 42px;
    font-weight: 800;
    letter-spacing: 1.5px;
    color: #ffffff;
    text-transform: uppercase;
    backdrop-filter: blur(10px);
    box-shadow: 
        0 4px 6px rgba(0, 0, 0, 0.1),
        0 1px 3px rgba(255, 255, 255, 0.05);
    transition: all 0.3s ease;
    line-height: 1.2;
}

.company-badge:hover {
    background: rgba(40, 40, 40, 0.95);
    border-color: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
    box-shadow: 
        0 8px 16px rgba(0, 0, 0, 0.15),
        0 2px 4px rgba(255, 255, 255, 0.05);
}

.problem-title {
    font-size: 22px;
    color: rgba(255, 255, 255, 0.9);
    font-weight: 900;
    font-size: 50px;
    letter-spacing: 0.5px;
}

.problem-text {
    color: rgba(255, 255, 255, 0.87);
    line-height: 1.8;
    font-size: 16px;
    padding: 0;
    overflow-wrap: break-word;
}

.problem-text h1,
.problem-text h2,
.problem-text h3 {
    color: #2cbb5d;
    margin: 24px 0 16px;
    font-weight: 600;
    border-bottom: 1px solid rgba(44, 187, 93, 0.2);
    padding-bottom: 8px;
}

.problem-text p {
    margin: 16px 0;
    color: rgba(255, 255, 255, 0.87);
}

.problem-text pre {
    background: rgba(44, 187, 93, 0.05);
    border: 1px solid rgba(44, 187, 93, 0.1);
    border-radius: 8px;
    padding: 16px;
    margin: 16px 0;
    overflow-x: auto;
}

.problem-text code {
    font-family: 'Menlo', 'Monaco', 'Courier New', monospace;
    font-size: 14px;
    background: rgba(255, 255, 255, 0.05);
    padding: 2px 6px;
    border-radius: 4px;
    color: #e6e6e6;
}

.description-actions {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-left: auto;
    margin-top: 16px;
}

.problem-text ul,
.problem-text ol {
    padding-left: 24px;
    margin: 16px 0;
}

.problem-text li {
    margin: 8px 0;
    color: rgba(255, 255, 255, 0.87);
}

.description-panel:hover {
    border-color: rgba(255, 255, 255, 0.15);
    transition: border-color 0.3s ease;
}

.solution-panel {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(26, 26, 26, 0.6);
    backdrop-filter: blur(10px);
}

.solution-header {
    padding: 15px 20px;
    background: rgba(40, 40, 40, 0.8);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px 12px 0 0;
}

.language-selector select {
    background: rgba(60, 60, 60, 0.8);
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: #fff;
    padding: 8px 16px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
}

.unlock-button {
    background: linear-gradient(135deg, rgba(44, 187, 93, 0.9), rgba(37, 157, 79, 0.9));
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 8px 16px;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    box-shadow: 0 2px 8px rgba(44, 187, 93, 0.2);
}

.unlock-button:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(44, 187, 93, 0.3);
    background: linear-gradient(135deg, rgba(44, 187, 93, 1), rgba(37, 157, 79, 1));
}

.code-editor {
    flex: 0 0 50%;
    background: rgba(13, 13, 13, 0.8);
    position: relative;
    padding: 20px;
    overflow-y: auto;
    border-radius: 12px 12px 0 0;
}

.code-content {
    font-family: 'Menlo', 'Monaco', 'Courier New', monospace;
    white-space: pre;
    color: #e6e6e6;
    font-size: 15px;
    line-height: 1.6;
    filter: blur(4px);
    user-select: none;
    opacity: 0.2;
}

.blur-overlay {
    position: absolute;
    inset: 0;
    background: rgba(26, 26, 26, 0.85);
    backdrop-filter: blur(12px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    z-index: 10;
}

.lock-icon {
    font-size: 24px;
    color: #2cbb5d;
    filter: drop-shadow(0 0 10px rgba(44, 187, 93, 0.3));
}

.blur-overlay span {
    color: #e6e6e6;
    font-size: 16px;
    font-weight: 500;
}

.blur-overlay .unlock-text {
    color: #8c8c8c;
    font-size: 14px;
}

pre {
    margin: 0;
    font-family: 'Menlo', 'Monaco', 'Courier New', monospace;
    font-size: 14px;
    line-height: 1.5;
    color: #e6e6e6;
}

/* Custom scrollbar */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background: #1a1a1a;
}

::-webkit-scrollbar-thumb {
    background: #4d4d4d;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
    background: #666;
}

@media (max-width: 1024px) {
    .leetcode-content {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }

    .description-panel {
        border-right: none;
        border-bottom: 1px solid #3e3e3e;
    }
}

.company-name {
    font-size: 44px;
    color: lavenderblush;
    margin-bottom: 16px;
    
}

/* Test Cases Section */
.testcases-wrapper {
    flex: 0 0 50%;
    background: rgba(13, 13, 13, 0.8);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
    border-radius: 0 0 12px 12px;
}

.testcase-header {
    padding: 15px 20px;
    background: rgba(30, 30, 30, 0.9);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.testcase-tabs {
    display: flex;
    gap: 16px;
}

.testcase-tab {
    color: #8c8c8c;
    font-size: 13px;
    cursor: pointer;
    padding: 4px 0;
    position: relative;
}

.testcase-tab.active {
    color: #fff;
}

.testcase-tab.active::after {
    content: '';
    position: absolute;
    bottom: -13px;
    left: 0;
    right: 0;
    height: 2px;
    background: #2cbb5d;
}

.testcase-content {
    flex: 1;
    overflow-y: auto;
    position: relative;
    padding: 20px;
}

.testcase-details {
    filter: blur(4px);
    opacity: 0.2;
    pointer-events: none;
}

.testcase-item {
    margin-bottom: 16px;
}

.testcase-label {
    color: #8c8c8c;
    font-size: 13px;
    margin-bottom: 4px;
}

.testcase-value {
    background: rgba(255, 255, 255, 0.05);
    padding: 8px 12px;
    border-radius: 4px;
    font-family: 'Menlo', 'Monaco', 'Courier New', monospace;
    font-size: 13px;
    color: #e6e6e6;
}

.testcase-result {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #2cbb5d;
    font-size: 13px;
}

.testcase-result .dot {
    width: 8px;
    height: 8px;
    background-color: #2cbb5d;
    border-radius: 50%;
}

/* Blur overlay styling */
.blur-overlay {
    position: absolute;
    inset: 0;
    background: rgba(26, 26, 26, 0.85);
    backdrop-filter: blur(12px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    z-index: 10;
}

.lock-icon {
    font-size: 24px;
    color: #2cbb5d;
    filter: drop-shadow(0 0 10px rgba(44, 187, 93, 0.3));
}

.blur-overlay span {
    color: #e6e6e6;
    font-size: 16px;
    font-weight: 500;
}

.blur-overlay .unlock-text {
    color: #8c8c8c;
    font-size: 14px;
}

/* Success indicators */
.success-indicator {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;
    padding: 8px 12px;
    background: rgba(44, 187, 93, 0.1);
    border-radius: 4px;
    color: #2cbb5d;
    font-size: 13px;
}

.success-indicator svg {
    width: 16px;
    height: 16px;
}

/* Test case tabs */
.testcase-tabs {
    display: flex;
    gap: 2px;
    padding: 0 20px;
    background: rgba(30, 30, 30, 0.9);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.testcase-tab {
    padding: 8px 16px;
    color: #8c8c8c;
    font-size: 13px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease;
}

.testcase-tab.active {
    color: #2cbb5d;
    border-bottom-color: #2cbb5d;
}

/* Custom scrollbar for test cases */
.testcase-value::-webkit-scrollbar {
    height: 4px;
}

.testcase-value::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
}

.testcase-value::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 2px;
}

.testcase-value::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.2);
}

/* Adjust scrollbars for better appearance */
.description-panel::-webkit-scrollbar,
.code-editor::-webkit-scrollbar,
.testcase-content::-webkit-scrollbar {
    width: 6px;
}

.description-panel::-webkit-scrollbar-track,
.code-editor::-webkit-scrollbar-track,
.testcase-content::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
}

.description-panel::-webkit-scrollbar-thumb,
.code-editor::-webkit-scrollbar-thumb,
.testcase-content::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
}

.description-panel::-webkit-scrollbar-thumb:hover,
.code-editor::-webkit-scrollbar-thumb:hover,
.testcase-content::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.2);
}

/* Remove margins that might cause unwanted space */
.solution-panel,
.description-panel {
    margin: 0;
    border-radius: 0;
}

/* Ensure content fills available space */
.description-content,
.code-content {
    height: 100%;
}

/* Solution Header Adjustments */
.solution-header {
    padding: 15px 20px;
    background: rgba(40, 40, 40, 0.8);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px 12px 0 0;
}

/* Description Header Adjustments */
.description-header {
    padding: 20px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* Testcase Header Adjustments */
.testcase-header {
    padding: 15px 20px;
    background: rgba(30, 30, 30, 0.9);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* Content Padding Adjustments */
.description-content {
    padding: 0 10px;
    height: auto;
}

.testcase-content {
    padding: 20px;
}

/* Responsive adjustments */
@media (max-width: 1400px) {
    .leetcode-content {
        grid-template-columns: minmax(500px, 45%) 1fr;
    }
}

@media (max-width: 1200px) {
    .leetcode-container {
        padding: 70px 15px 15px 15px;
    }
    
    .leetcode-content {
        gap: 15px;
    }
}

/* Improve scrollbar styling */
.description-panel::-webkit-scrollbar {
    width: 8px;
}

.description-panel::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.description-panel::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
}

.description-panel::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.2);
}

/* Add these styles */
.header-right {
    display: flex;
    align-items: center;
    gap: 16px;
}

.share-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    color: #e6e6e6;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    backdrop-filter: blur(5px);
}

.share-button:hover {
    background: rgba(255, 255, 255, 0.15);
    transform: translateY(-1px);
}

.share-button:active {
    transform: translateY(0);
}

.share-button svg {
    font-size: 14px;
}

.original-link {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background: rgba(44, 187, 93, 0.1);
    border: 1px solid rgba(44, 187, 93, 0.2);
    border-radius: 6px;
    color: #2cbb5d;
    font-size: 14px;
    text-decoration: none;
    transition: all 0.2s ease;
    backdrop-filter: blur(5px);
}

.original-link:hover {
    background: rgba(44, 187, 93, 0.15);
    transform: translateY(-1px);
}

.original-link:active {
    transform: translateY(0);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .header-right {
        gap: 8px;
    }

    .share-button,
    .original-link {
        padding: 6px 12px;
        font-size: 13px;
    }
}

/* Popup Styling */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
    transition: background 0.3s ease;
}

.popup-content {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
    max-width: 500px;
    text-align: center;
    color: #e6e6e6;
    animation: fadeIn 0.3s ease-out;
}

.popup-content h2 {
    margin-top: 0;
    color: #2cbb5d;
    font-size: 24px;
    font-weight: 600;
}

.popup-content p {
    margin: 20px 0;
    color: #d1d1d1;
    font-size: 16px;
    line-height: 1.5;
}

.popup-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.proceed-button {
    background: linear-gradient(135deg, #2cbb5d, #1ba95d);
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
    transition: background 0.3s ease, transform 0.2s ease;
}

.proceed-button:hover {
    background: linear-gradient(135deg, #1ba95d, #169754);
    transform: translateY(-2px);
}

.close-button {
    background: rgba(255, 255, 255, 0.1);
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    color: #e6e6e6;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
}

.close-button:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
}

/* Animation for Popup */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Action buttons styling - made more subtle */
.action-buttons {
    display: flex;
    gap: 12px;
    margin-bottom: 24px;
}

.action-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 18px;
    background: rgba(40, 40, 40, 0.7);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    text-decoration: none;
    backdrop-filter: blur(8px);
}

.action-button:hover {
    background: rgba(50, 50, 50, 0.8);
    color: rgba(255, 255, 255, 0.9);
    transform: translateY(-1px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .company-badge {
        font-size: 32px;
        padding: 12px 24px;
    }

    .action-button {
        padding: 8px 14px;
        font-size: 13px;
    }

    .problem-title {
        font-size: 18px;
    }
}

/* Header content layout */
.header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0 32px 0;
    gap: 24px;
}

/* Company badge styling */
.company-badge {
    display: inline-flex;
    align-items: center;
    padding: 16px 32px;
    background: rgba(30, 30, 30, 0.95);
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 16px;
    font-size: 42px;
    font-weight: 800;
    letter-spacing: 1.5px;
    color: #ffffff;
    text-transform: uppercase;
    backdrop-filter: blur(10px);
    box-shadow: 
        0 4px 6px rgba(0, 0, 0, 0.1),
        0 1px 3px rgba(255, 255, 255, 0.05);
    transition: all 0.3s ease;
    line-height: 1.2;
}

/* Header buttons container */
.header-buttons {
    display: flex;
    gap: 12px;
    align-items: center;
}

/* Action button styling */
.action-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 24px;
    background: rgba(40, 40, 40, 0.95);
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 12px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    text-decoration: none;
    backdrop-filter: blur(8px);
}

.action-button:hover {
    background: rgba(50, 50, 50, 0.95);
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .header-content {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
    }

    .company-badge {
        font-size: 32px;
        padding: 12px 24px;
    }

    .action-button {
        padding: 10px 20px;
        font-size: 14px;
    }
}