/* Main container */
.admin-dashboard {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    color: rgba(255, 255, 255, 0.9);
}

/* Header styles */
.admin-header {
    margin-bottom: 48px;
    text-align: center;
}

.admin-header h1 {
    font-size: 2.4rem;
    font-weight: 500;
    letter-spacing: -0.5px;
    margin-bottom: 24px;
    color: rgba(255, 255, 255, 0.95);
}

/* Tab navigation */
.admin-tabs {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-bottom: 40px;
}

.tab-button {
    padding: 10px 24px;
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.95rem;
    transition: all 0.2s ease;
}

.tab-button:hover {
    background: rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.8);
}

.tab-button.active {
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.95);
    border-color: rgba(255, 255, 255, 0.2);
}

/* Form sections */
.add-company, .add-question {
    background: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    padding: 32px;
    margin-bottom: 32px;
}

.section-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 24px;
    color: rgba(255, 255, 255, 0.9);
}

/* Form elements */
form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

input, select, textarea {
    width: 100%;
    padding: 12px 16px;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: rgba(255, 255, 255, 0.9);
    font-size: 0.95rem;
    transition: all 0.2s ease;
}

input:focus, select:focus, textarea:focus {
    border-color: rgba(255, 255, 255, 0.2);
    background: rgba(0, 0, 0, 0.3);
    outline: none;
}

/* Code editor */
.code-editor {
    font-family: 'Fira Code', monospace;
    background: rgba(0, 0, 0, 0.3);
    padding: 16px;
    border-radius: 8px;
    line-height: 1.6;
    min-height: 200px;
}

/* List sections */
.companies-list {
    margin-top: 48px;
}

.company-item {
    background: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    padding: 24px;
    margin-bottom: 16px;
    transition: all 0.2s ease;
}

.company-item:hover {
    background: rgba(255, 255, 255, 0.03);
}

/* Action buttons */
button {
    padding: 12px 24px;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 8px;
    color: rgba(255, 255, 255, 0.9);
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

button:hover {
    background: rgba(255, 255, 255, 0.15);
    transform: translateY(-1px);
}

/* Modal styles */
.modal-overlay {
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(4px);
}

.modal-content {
    background: rgba(30, 30, 30, 0.95);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Responsive design */
@media (max-width: 768px) {
    .admin-dashboard {
        padding: 20px;
    }

    .add-company, .add-question {
        padding: 20px;
    }

    .admin-header h1 {
        font-size: 2rem;
    }

    .tab-button {
        padding: 8px 16px;
    }
}
